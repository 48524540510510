.inlineDiv {
  display: inline !important;
}

.cardHeaderMainDiv > div > .collapse {
  border: #dee2e6 1px solid !important;
}

.cardHeader {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.2s ease;
  color: white;
  cursor: pointer;
  background: #6c757d;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.rowLabelCenter {
  align-self: center !important;
  justify-content: center !important;
  padding: 3rem !important;
}

.stepBtn {
  border: 1px solid #02a499 !important;
}
.stepBtn:disabled {
  background-color: lightgray !important;
  border: 1px solid darkgrey !important;
}

.step1Btn {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.step2Btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

#GrievanceAttachmentControl .filepond--list-scroller {
  display: none !important;
}
