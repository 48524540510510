*,
*:after,
*:before {
    margin: 0;
    padding: 0;
}

.hidden {
    display: none;
}

input[type="button"] {
    margin-top: 20px;
}

.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    transition: all 1000ms ease;
    margin-top: 35px;
    margin-bottom: -25px;
}

.stepPara {
    border-left: 3px solid #ced4da;
    margin-left: 19px !important;
    padding-left: 24px !important;
    padding-bottom: 35px !important;
}

.activated {
    box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
}

.divider {
    height: 40px;
    width: 2px;
    margin-left: 4px;
    transition: all 800ms ease;
}

#progress li {
    margin-bottom: -51px;
}

.blue {
    background-color: rgba(82, 165, 255, 1);
}

.meeting {
    background-color: rgb(214, 157, 33);
}

.green {
    background-color: rgba(92, 184, 92, 1);
}

.red {
    background-color: rgba(255, 148, 148, 1);
}

.grey {
    background-color: rgba(201, 201, 201, 1);
}

.border-around {
    border: "1px solid darkgrey !important";
}

.file-upload {
    display: block;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.file-upload .file-select {
    display: block;
    border: 2px solid #ced0da;
    color: #34495e;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: left;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select .file-select-button {
    background: #ced0da;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.file-upload .file-select .file-select-name {
    line-height: 40px;
    display: inline-block;
    padding: 0 10px;
}

.file-upload .file-select:hover {
    border-color: #34495e;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
    background: #34495e;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
    border-color: #3fa46a;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
    background: #3fa46a;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
    opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
    cursor: default;
    display: block;
    border: 2px solid #dce4ec;
    color: #34495e;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    text-align: left;
    background: #ffffff;
    overflow: hidden;
    position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
    background: #dce4ec;
    color: #666666;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
    line-height: 40px;
    display: inline-block;
    padding: 0 10px;
}

.activeTab {
    background-color: #02a499 !important;
    color: white !important;
}

.errspan {
    float: right;
    margin-right: 6px;
    margin-top: -20px;
    position: relative;
    z-index: 2;
    color: red;
}

.topBottonMargin {
    margin-top: 40px !important;
    margin-bottom: 45px !important;
}



.bg-light-grey {
    background-color: #d3d3d3;
}

.not-allowed {
    cursor: help;
}

.modal-content-grievance {
    width: 800px !important;
}

.glog-card {
    border: #dee2e6 1px solid !important;
    width: 529px !important;
    margin-left: 13px !important;
    border-radius: 0px !important;
}

.glog-header {
    padding: 13px 18px 14px 11px !important;
    background: #686e82 !important;
    width: 527px !important;
    border: 1px solid white !important;
    margin-top: 0px !important;
}

.datepickerZindex .react-datepicker-popper {
    z-index:1000!important;
}