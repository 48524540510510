/** @format */

.App {
  text-align: center;
}

.dispNone {
  display: none;
}

.btn-primary {
  background-color: #23325b;
}

.btn-primary:hover {
  background-color: #2f4daa;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.dis-none {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.disabled {
  background-color: #f7f7f7;
  cursor: text;
}

label {
  font-weight: 400;
}

.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.5) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.react-bootstrap-table-pagination ul.pagination {
  float: right;
}

.react-bootstrap-table .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
  margin: 10px 6.5px;
}

.react-bootstrap-table th.sortable {
  cursor: pointer;
}

.react-bootstrap-table th .order>.dropdown>.caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}

.react-bootstrap-table th .order>.dropup>.caret {
  margin: 10px 0;
  color: #cccccc;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0px;
  border-bottom: 4px dashed;
}

.bare-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1.2rem;
  height: 1.2rem;
}

.custom-control-label-override::before,
.custom-control-label-override::after {
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.custom-control-label {
  line-height: 28px;
}
.line-height-inherit {
  line-height: inherit;
}
.custom-checkbox {
  display: inline-block;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.15rem;
}

.app-search button {
  position: absolute;
  top: 0px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  border: none;
  right: 0;
  cursor: pointer;
  background: #759fbc;
  height: 34px;
  padding: 0 20px;
  border-radius: 0 30px 30px 0;
}

.as-link {
  font-size: 13px;
  padding-top: 5px;
}

.advance-search .md-form {
  margin: 5px 0;
}

label.search-label {
  width: 100%;
}

.bbLight {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

div.bbLight:last-child {
  border: none !important;
}

.md-form .form-control.is-invalid,
.was-validated .md-form .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  padding-right: 0;
}

.enlarged #wrapper #sidebar-menu ul ul {
  position: absolute;
  left: -200px;
}

.r1 {
  background-color: #ffffff;
  padding: 30px 0;
  min-height: 80px;
}

.r2 {
  background-color: #f7f7f7;
  padding: 30px 0;
  min-height: 80px;
}

.r3 {
  background-color: #eeeeee;
  padding: 30px 0;
  min-height: 80px;
}

input[switch]+label.size2 {
  width: 70px;
}

input[switch]:checked+label.size2:after {
  left: 47px;
}

.actionCol {
  width: 80px !important;
  text-align: center;
}

.react-bootstrap-table {
  overflow-x: auto !important;
}

.enlarged .side-menu {
  position: fixed;
}

.dg-none th {
  height: 0 !important;
  padding: 0;
  border: none !important;
}

#teamMembers>.react-bootstrap-table .table:first-child {
  margin-bottom: -1px;
}

#teamMembers .react-bootstrap-table-pagination {
  margin-top: 15px;
}

.disabletbody {
  opacity: 0.5;
  pointer-events: none;
}

.select__menu {
  margin-top: 5px !important;
  z-index: 999999;
}

.bg-primary {
  background-color: #759fbc !important;
}

#teamMembers .table {
  margin-bottom: 0;
}

.search-btn-panel {
  margin-top: 46px;
}

.search-link {
  margin: 0 15px;
  font-size: 16px;
  color: #759fbc !important;
  font-weight: 400;
}

.search-link:hover {
  color: #759fbc;
}

.search-link-line {
  font-size: 20px;
  color: #759fbc;
  font-weight: 400;
}

.actionCol .btn {
  padding: 0.15rem 0.3rem;
  font-size: 8px;
}

.actionCol .ion {
  font-size: 10px;
}

.search-btn-panel-team {
  margin-top: -4px;
}

.search-btn-panel-team .dropdown {
  display: initial;
}

.d-tabs {
  padding: 3px 7px;
}

.password-icon {
  position: absolute;
  right: 18px;
  top: 270px;
  cursor: pointer;
}

.inputBorderRed {
  border: solid 1px red;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #02a499 !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #01766e !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #02a499 !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #02a499 !important;
}

.topUp {
  top: -0.3rem !important;
}

.topUpMinor {
  top: -1rem !important;
}

.verifySms {
  margin-bottom: 1px;
}

.step4Input {
  margin-bottom: 1px;
}

.headerPos {
  position: relative;
  top: 0.5rem;
  font-size: 133%;
}

.signUpStepper {
  position: absolute;
  top: 6.5rem;
  left: 0.5rem;
  font-size: 92%;
}

.step1Parent {
  margin-top: 3rem;
}

.lastrow {
  display: inline-block;
}

.spinnerCard {
  position: absolute;
  top: 50%;
  left: 50%;
  background-size: 100%;
}

.spinnerHeight {
  height: 15rem;
}

.reviewInfoHeading {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.oneLine {
  white-space: nowrap;
}

.mobile-margin-cmm {
  margin-left: 1rem;
}

.bucketSpecialTbl>.react-bootstrap-table {
  overflow-x: hidden !important;
}

._loading_overlay_wrapper>.react-bootstrap-table {
  min-height: 8rem !important;
}

#Sub_Teams_2_Records>div>div._loading_overlay_overlay {
  margin-top: 2rem !important;
}

.css-79elbk>div {
  min-height: 6rem !important;
}

.messageText {
  font-size: 95%;
}

.reduceLetterSpace {
  letter-spacing: 0.1px !important;
}

@media (max-width: 575px) {
  .tblBucket {
    width: 50% !important;
  }
}

.reviewScreenHeader>thead>tr>th {
  color: white !important;
}

.reviewParentDiv>table {
  margin-bottom: 0;
}

.reviewIcon {
  color: #ffc107;
}

.bgFieldMessage {
  background: #00000045;
  height: 2rem;
  padding-top: 0.4rem;
}

.fontSmaller {
  font-size: smaller;
}

.parent-expand {
  background-color: #337ab733;
}

.csvIconSize {
  font-size: 1.5rem;
  display: flex;
  cursor: pointer !important;
}

.NoDataFoundTable {
  align-self: center !important;
  justify-content: center !important;
  padding: 1rem !important;
}
.FoFManagersTable {
  align-self: center !important;
  justify-content: end !important;
  padding: 1rem !important;
}
.NoUpdatedFieldFound {
  align-self: center !important;
  justify-content: center !important;
}

.yearPP {
  display: inline-block;
  min-width: 4rem;
}

.height17px {
  height: 17px !important;
}

.removedSection {
  background: rgb(0 0 0 / 25%) !important;
}

.inlineTable {
  display: inline-table;
}

.progress {
  height: 1.4rem;
  width: 75%;
  font-size: x-small;
}

.progress-bar {
  background-color: #626ed4;
  /* min-width: fit-content;
  max-width: 105%; */
  /* color: black; */
}

.ignored_pg {
  min-width: fit-content !important;
  color: black;
}

.error_pg {
  min-width: fit-content !important;
  color: black;
}

.updated_pg {
  min-width: fit-content !important;
  color: black;
}

.removed_pg {
  min-width: fit-content !important;
  color: black;
}

.showCV {
  font-size: small;
}

.showCV:visited {
  text-decoration: none !important;
}

.showCV:hover {
  text-decoration: underline !important;
  font-weight: 575;
}

.showCV:active {
  text-decoration: underline !important;
  font-weight: 575;
}

/*Membership and useraccount cards*/
.ps-column-mac {
  flex: 25%;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 767px) {
  .ps-column-mac {
    flex: 100%;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .ps-row-mac {
    flex-direction: column;
  }
}

/*Membership and useraccount cards ENDING*/
/* @media screen and (max-width: 782px) {
  .resetPassMobile {
    padding-right: 8px;
    padding-left: 0;
    font-size: smaller;
    padding-top: 3px;
    padding-bottom: 3px;
  }
} */
@media screen and (max-width: 782px) {
  .resetPassParent {
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 782px) {
  .restPwdBtn {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
    /* float: right; */
  }
}

@media screen and (max-width: 782px) {
  #username-inline {
    font-size: small;
  }
}

/* @media screen and (max-width: 1414px) {
  .memtype {
    font-size: small;
  }
} */

@media screen and (max-width: 576px) {
  .restPwdBtn {
    float: right;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 567px) {
  .resetPassMobile {
    font-size: initial;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  #username-inline {
    font-size: initial;
  }
}

@media screen and (max-width: 1414px) {
  .pdMemType {
    padding-right: 0;
  }
}

.editMemPen {
  padding-top: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.text_ctrl>.form-group>.md-form {
  margin-top: 0.6rem !important;
}

.expandTbl {
  padding-bottom: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;
  font-size: small;
  font-style: italic;
}

.disableDownloadIcons {
  pointer-events: none !important;
  color: lightgray !important;
}

.modal-45w {
  max-width: 45%;
}

.modal-20w {
  max-width: 20%;
}

.modal-52w {
  max-width: 52%;
}

.modal-55w {
  max-width: 55%;
}
.modal-60w {
  max-width: 60%;
}
.modal-85w {
  max-width: 85%;
}
.modal-95w {
    max-width: 95%;
}
.modal-110{
  min-height: 120%;
}

.table>caption {
  text-align: left;
  caption-side: top;
  padding: 0;
  color: white;
  background-color: #686e82;
}

.table>.noData>caption {
  text-align: left;
  caption-side: top;
  padding: 0;
  color: white;
  background-color: #686e82;
}

.sub_tbl_caption {
  margin: 5px;
  padding: 3px;
}
#emailConfigTabs-tab-subscribers > i,
#emailConfigTabs-tab-unsubscribed > i,
#emailConfigTabs-tab-settings > i,
#emailConfigTabs-tab-activityLogs>i {
  font-size: large;
}

.emailConfigHeader {
  text-decoration: underline;
  cursor: pointer;
}

.emailConfigHeaderHightLight {
  color: #626ed4;
}

.mainHeaderLine {
  display: inline-flex;
  align-items: center;
}

.nested-cs-modal {
  background: rgba(0, 0, 0, 0.3);
}

.unsubscrActionBtn {
  font-size: 0.69rem
}

.reviewRegRequestBody {
  background: rgba(0, 0, 0, 0.08);
}

.Ddlfilters {
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  background: #e9ecef;
  margin-bottom: 1rem;
  max-height: 8em;
  overflow: hidden;
  overflow-y: scroll;
}
.font-weight-450{
  font-weight: 450;
}
#manageSubscribe{
  color:#626ed4;
  cursor: pointer;
}
.nestedComps{
  margin-left: -1rem;
}
.toggle.android {
  border-radius: 5px;

}

.toggle.android .toggle-handle {
  border-radius: 5px;
}
.toggle-group label{
  font-size: small;
  /* width: max-content; */
}
.action-icons{
  float: right;
  padding-right: 0.6rem;
}
.action-def-mems{
  float: right;
  padding-right: 2rem;
} 
.form-control.DefaultMemberSearchField::placeholder {
  font-size: smaller;  
}

@media screen and (max-width: 600px) {
.mobileActions {
    width: max-content;
  }
}
@media screen and (max-width: 600px) {
  .delbtnel {
    margin-top: 1vh;
  }
}

@media screen and (min-width: 768px) {
  .subscribersModalCustomWidth {
      max-width: 65%;
    }
}
@media screen and (min-width: 1400px) {
  .subscribersModalCustomWidth {
    max-width: 63%;
  }
}

@media screen and (min-width: 1500px) {
  .subscribersModalCustomWidth {
    max-width: 69%;
  }
}
@media screen and (min-width: 1650px) {
  .subscribersModalCustomWidth {
    max-width: 46%;
  }
}
@media screen and (min-width: 1700px) {
  .subscribersModalCustomWidth {
    max-width: 58%;
  }
}
.btnElAction{
  font-size: small;
}

.emailConfChkBox {
  margin-left: 2px;
}