.content > .team-container,
.team-container {
  margin-top: 110px !important;
}

@media (max-width: 1024px) {
  .content > .team-container,
  .team-container {
    margin-top: 145px !important;
  }
}

@media (max-width: 527px) {
  .content > .team-container,
  .team-container {
    padding: 5px !important;
    margin-top: 160px !important;
  }
}

.content > .team-container .react-bootstrap-table .reset-expansion-style {
  padding: 0;
}

.content > .team-container .react-bootstrap-table .row-expansion-style {
  padding: 8px;
}

.content > .team-container .react-bootstrap-table .row-expand-slide-appear {
  max-height: 0;
  overflow: hidden;
}

.content
  > .team-container
  .react-bootstrap-table
  .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: max-height 3s linear;
}

.content > .team-container .react-bootstrap-table .row-expand-slide-exit {
  max-height: 1000px;
}

.content .team-style .text-primary {
  color: #759fbc !important;
}

.content
  > .team-container
  .react-bootstrap-table
  .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95);
}

.content > .team-container .rpl-Loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10000;
}

.lds-ripple {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  border: 4px solid #b0fffa;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.perm-btn.lds-ripple {
  position: relative;
  display: inline-block;
  margin: auto;
  z-index: 3;
  width: 15px;
  height: 15px;
  margin-bottom: -2px;
}

.perm-btn.lds-ripple div {
  border: 2px solid #b0fffa;
  animation: btn-lds-ripple 0.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.perm-btn.lds-ripple div:nth-child(2) {
  border: 2px solid #b0fffa;
  animation: btn-lds-ripple 0.6s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes btn-lds-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    width: 16px;
    height: 16px;
    opacity: 0;
  }
}

.bold-input {
  font-size: 25px;
  font-weight: bold;
}

.bold-input::-webkit-input-placeholder {
  color: #c0c0c0;
  font-style: italic;
}

.scopelist {
  max-height: 380px;
  overflow-y: scroll;
}

/* .permissionsList {
    max-height: 400px;
    overflow-y: scroll;
} */

.round-check{
  position: relative;
}

.round-check label, .ST-check {
  background-color: #282828;
  box-shadow: inset rgba(0, 0, 0, 0.75) 0px -1px 2px;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  border: 1px solid white;
  width: 18px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

    .round-check.uncheck-light label, .ST-check.check-light {
        background-color: #c0c0c0;
    }

.round-check.uncheck-default label {
  background-color: #b3e4ff;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #555590 0px -1px 2px,
    #b2e4ff 1px 1px 6px 1px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

    .round-check.uncheck-danger label, .ST-check.check-danger {
        background-color: #ff738b;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #b51d65f7 0px -1px 2px, #f3c9b0 1px 1px 6px 1px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
    }

.round-check input[type="checkbox"] {
  visibility: hidden;
}

    .round-check input[type="checkbox"]:checked + label, .ST-check.check-success {
        background-color: #b4f8bc;
        /* background-color: #ABFF00; */
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #83b51df7 0px -1px 2px, #70ff08 1px 1px 6px 1px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
    }
    .round-check.uncheck-default label, .ST-check.check-default,
    .round-check.checked-default input[type="checkbox"]:checked + label {
        background-color: #b3e4ff;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #555590 0px -1px 2px, #b2e4ff 1px 1px 6px 1px;
        transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
    }

    .round-check input[data-child-check="true"] + label, .ST-check[data-child-check="true"] {
        border-radius: 0;
    }
/*.round-check input[data-checked='true'][data-disabled='true'] + label {
        box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, #83b51df7 0px -1px 2px, #70ff08 1px 1px 6px 1px;
        transition: all .5s;
        -o-transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
    }*/
.round-check.check-success input[type="checkbox"]:checked + label {
  background-color: #02a499;
}

.round-check input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*Special team classes are also used in round-check css*/
/*Special team Checks*/
.ST-check {
    position: relative;
}

    .ST-check.check-small {
        width: 12px;
        height: 12px;
    }





    #MemberlistScroll div.active,
    #TeamList li.active {
        background-color: #38a4f8 !important;
        color: #ffffff !important;
    }

#MemberlistScroll,
#TeamList,
#deleteChildTeamList {
  max-height: 300px;
  overflow-y: scroll;
}

.perm-row {
  height: 40px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.scopelist .perm-row {
  height: 40px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.perm-col-1 {
  width: 60%;
}

.perm-col-2 {
  width: 10%;
}

.perm-row.perm-row-hide {
  height: 0;
  overflow: hidden;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.scopelist .perm-row-hide {
  display: none;
}

.scope-colaps {
  height: 30px;
  text-align: right;
  float: right;
  width: 97%;
  position: relative;
  top: -27px;
}

@media (max-width: 407px) {
  .btn-x-sm {
    min-width: 80px;
  }
}

@media (max-width: 527px) {
  .table {
    width: auto;
    min-width: 100%;
  }
}

.permissionsList th.text-center {
  width: 10%;
}

.opacity-half {
  opacity: 0.5 !important;
}

.opacity-quater {
  opacity: 0.25 !important;
}

.assign-member {
  top: -2px;
  right: -50%;
  left: -50%;
  margin: 0 auto;
}

.bg-perm-info {
  background-color: #78b5df !important;
}

@media (max-width: 991px) {
  .assign-member {
    top: unset;
    bottom: 0;
  }
}

.permissionTablePagination .react-bootstrap-table-pagination {
  margin-left: 0;
  margin-right: 0;
}

#DeleteModalTeams .bullets {
  width: 7px;
  height: 7px;
  border: 2px solid #ccc;
  margin: auto;
  left: 3px;
  top: 0;
  bottom: -2px;
  position: absolute;
  border-radius: 50%;
}

#defaulPermissionBody {
  overflow-y: unset !important;
}

#defaulPermissionTab .nav-link.active {
  background-color: #759fbc !important;
  color: #ffffff !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.positionList td {
  padding: 0px 15px !important;
}

.positionList table.table.table-bordered h4 {
  margin: 5px 0;
}

#AssignMembers .close {
  color: white !important;
}

.styled-modal .modal-title {
  text-transform: uppercase !important;
  color: white;
  margin-top: 0px !important;
}

.styled-modal .modal-header button.close {
  color: white !important;
}

#MemberlistScroll .active {
  background-color: #38a4f8 !important;
  color: #ffffff !important;
}
#TeamlistScroll .active {
  background-color: #38a4f8 !important;
  color: #ffffff !important;
}

.changeColor {
  color: #759fbc !important;
}

.team-menu .dropdown-item {
  padding: 0.25rem 0.25rem !important;
}

#pageDropDown {
  margin-top: -2px;
  padding: 1px 8px;
}
/*.members-pagination ul.pagination{
   margin-bottom:3px!important
}*/
.positionList table .reset-expansion-style,
.positionList table .row-expansion-style {
  padding: 0 !important;
}
.positionList table .reset-expansion-style table td {
  padding: 0 !important;
}
#childTeamList {
  max-height: 300px;
  overflow-y: auto;
}
/*-------[Bulk Team Progress Bar]-----------*/
#bulkTeam-PB.progress {
  height: 7px;
  overflow: visible;
  margin-bottom: 34px;
}
/*bar setting*/
#bulkTeam-PB.progress .progress-bar {
  height: 7px;
  position: relative;
}
/*label setting*/
#bulkTeam-PB.progress .progress-label {
  position: absolute;
  padding: 2px 6px;
  right: -17px;
  width: 39px;
  background-color: #1e2629;
  bottom: -33px;
}
/*label arrow setting*/
#bulkTeam-PB.progress .progress-label:after {
  content: "";
  max-width: 0;
  height: 0;
  left: 0;
  top: -11px;
  right: 0;
  margin: 0 auto;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid black;
  position: absolute;
}
/*-------[End Of Progress Bar]-----------*/
