/* Gmail style scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 2px;
}
::-webkit-scrollbar-track {
  border-width: 0;
}
::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 16px;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}
::-webkit-scrollbar-track:horizontal {
  border-width: 4px 0 0;
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(220, 172, 0, 0.05);
  box-shadow: inset 1px 0 0 rgba(220, 172, 0, 0.1);
}
::-webkit-scrollbar-track:horizontal:hover {
  box-shadow: inset 0 1px 0 rgba(220, 172, 0, 0.1);
}
::-webkit-scrollbar-track:active {
  background-color: rgba(220, 172, 0, 0.05);
  box-shadow: inset 1px 0 0 rgba(220, 172, 0, 0.14),
    inset -1px 0 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-track:horizontal:active {
  box-shadow: inset 0 1px 0 rgba(220, 172, 0, 0.14),
    inset 0 -1px 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(220, 172, 0, 0.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.1),
    inset 0 -1px 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb:horizontal {
  border-width: 6px 1px 1px;
  padding: 0 0 0 100px;
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.1),
    inset -1px 0 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(220, 172, 0, 0.4);
  box-shadow: inset 1px 1px 1px rgba(220, 172, 0, 0.25);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgba(220, 172, 0, 0.5);
  box-shadow: inset 1px 1px 3px rgba(220, 172, 0, 0.35);
}
::-webkit-scrollbar-track {
  border-width: 0 1px 0 6px;
}
::-webkit-scrollbar-track:horizontal {
  border-width: 6px 0 1px;
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(220, 172, 0, 0.035);
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.14),
    inset -1px -1px 0 rgba(220, 172, 0, 0.07);
}
::-webkit-scrollbar-thumb {
  border-width: 0 1px 0 6px;
}
::-webkit-scrollbar-thumb:horizontal {
  border-width: 6px 0 1px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
body::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  background-color: #f5f5f5;
  border: solid #fff;
  border-width: 0 0 0 3px;
  box-shadow: inset 1px 0 0 rgba(220, 172, 0, 0.14),
    inset -1px 0 0 rgba(220, 172, 0, 0.07);
}
body::-webkit-scrollbar-track-piece:horizontal {
  border-width: 3px 0 0;
  box-shadow: inset 0 1px 0 rgba(220, 172, 0, 0.14),
    inset 0 -1px 0 rgba(220, 172, 0, 0.07);
}
body::-webkit-scrollbar-thumb {
  border-width: 1px 1px 1px 5px;
}
body::-webkit-scrollbar-thumb:horizontal {
  border-width: 5px 1px 1px;
}
body::-webkit-scrollbar-corner {
  background-clip: padding-box;
  background-color: #f5f5f5;
  border: solid #fff;
  border-width: 3px 0 0 3px;
  box-shadow: inset 1px 1px 0 rgba(220, 172, 0, 0.14);
}
