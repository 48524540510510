/*
    EditUserStyle Sheet
    Author: Amir I.

*/
.content > .member-container-fluid {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-top: 35px;
}
.content > .member-container-fluid input::-webkit-input-placeholder {
  color: #c0c0c0;
  font-style: italic;
}
.content > .member-container-fluid .md-form .prefix,.small-prefix .prefix {
  font-size: 14px!important;
  position: absolute;
  top: 5px;
  color: #a4a1a1;
}
    .content > .member-container-fluid .md-form .prefix ~ input,
    
    .content > .member-container-fluid .md-form .prefix ~ textarea
    {
        margin-left: 15px;
        padding-left: 15px;
        width: 80%;
    }

@media (max-width: 1040px) {
  .content > .member-container-fluid {
    margin-top: 130px;
  }
}

@media (max-width: 527px) {
  .content > .member-container-fluid {
    padding: 5px !important;
    margin-top: 180px;
  }
}
/* @media(max-width:420px) {
    
    .d-tabs {
        margin-right: 0px !important;
        display: unset;
        font-size: 14px !important;
        padding: 3px !important;
    }
} */
@media (min-width: 350px) and (max-width: 488px) {
  .d-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 12px !important;
    padding: 3px !important;
  }
}

.modal-max-width {
  max-width: 600px !important;
}
.jobcarousel .carousel-indicators {
  margin-bottom: 5px;
}
.jobcarousel .carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.7);
}
.jobcarousel .carousel-control-prev,
.jobcarousel .carousel-control-next {
  color: black !important;
  width: 15px !important;
  top: unset;
  height: 50%;
  align-items: start;
  -ms-flex-align: start;
}
.jobcarousel .carousel-control-prev {
  margin-left: 12px;
}
.jobcarousel .carousel-control-next {
  margin-right: 12px;
}
.jobcarousel .carousel-control-prev .carousel-control-prev-icon,
.jobcarousel .carousel-control-next .carousel-control-next-icon {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  width: unset !important;
  height: unset !important;
  background: unset !important;
  margin-top: 15px;
}
.jobcarousel .carousel-control-next .carousel-control-next-icon:before {
  content: "\f054";
}
.jobcarousel .carousel-control-prev .carousel-control-prev-icon:before {
  content: "\f053";
}

.c-pointer {
  cursor: pointer !important;
}
.password-icon-position {
  position: absolute;
  right: 0;
  top: 5px;
}

.custom-control-label-new {
  /* font-size: 15px;
            letter-spacing: 0.5px; */
  line-height: 28px;
  margin-bottom: 0;
}

.tickIcon {
  margin-right: 8px;
  margin-left: -6px;
  color: green;
}
.crossIcon {
  margin-right: 9.5px;
  margin-left: -3px;
  color: red;
}

.facility-filter .dropdown-menu-body.dropdown-menu.show {
  padding: 10px 0 !important;
  margin: 0 !important;
}
.facility-filter .ffi {
  font-size: 16px !important;
  margin-left: 0 !important;
}

.jobhistory-datepicker {
    display: block;
    width: 79px!important;
}
.addressMemberForm #state {
    padding: 10px 0px 9px;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    box-shadow: unset;
}
    .addressMemberForm #state.is-invalid, .was-validated #state:invalid, #state.is-valid, .was-validated #state:valid{
        background-position-x:right
    }
    .addressMemberForm .state-container .MuiInput-underline::after {
        border-bottom-width: 1px
    }
.addressMemberForm .state-container .MuiInput-underline {
    margin-top: 1.5rem;
}
.addressMemberForm #state-label {
    line-height:inherit;
}
.addressMemberForm #state-helper-text {
    margin-top: 6px;
    font-size: 11px;
}
.addressValidUSPS{
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
}