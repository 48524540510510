.spinnerCard {
  position: absolute;
  top: 50%;
  left: 50%;
  background-size: 100%;
}

.spinnerFacilityCategory {
  align-self: center !important;
  justify-content: center !important;
  padding: 4rem !important;
}

.h1tag,
.h2tag,
.h3tag,
.h4tag,
.h5tag,
.h6tag {
  margin: 3px 0 !important;
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
}
.text-primary-card {
  color: #759fbc !important;
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
.facilityTabSection {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #495057;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px !important;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}
.facilityCollapsBtn {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.2s ease;
  color: white;
  cursor: pointer;
  background: #6c757d;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.header_1tk9zku {
  float: right;
}

.customDelete {
  outline: none;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  color: white;
}
.container_9tizg4 {
  z-index: 1050 !important;
}
.scrollWidthMapDiv {
  width: 50rem;
}
.modalshadow {
  background: #f8f9fa;
}
body.modal-open {
  overflow-y: hidden;
  position: fixed;
  right: 1.5vh !important;
  left: 0;
  padding-right: 0 !important;
  top: 0;
  bottom: 0;
}

/* body {
  height: 200vh;
  overflow-y: hidden; 
  padding-right: 15px; 
} */

.accordianContainerBorder {
  border: #dee2e6 1px solid !important;
}
.facilityCategoriesList > div > .collapse {
  border: #dee2e6 1px solid !important;
}
.parentLogsDiv {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 0.2vw;
  padding-left: 0.2vw;
}
.facilityAccordianHeight {
  min-height: 20vh;
}

.mapsHeader {
  padding: 1.25rem;
  flex: 1 1 auto;
}

.cursorPointer {
  cursor: pointer !important;
}

.radius-2px {
  border-radius: 2px !important;
}

.radius-2px > .switch-group > .btn-light {
  border-radius: 2px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.info-icon {
  color: #759fbc !important;
}

.smallNceptLabel {
  font-size: 60%;
  vertical-align: super;
}

.dropped {
  background-color: #ff3d00 !important;
}

.grayBackground {
  background-color: #b6b6b6 !important;
}

.raised {
  background-color: #53b096 !important;
}

.darkLabel {
  color: #9fa9a6 !important;
}

.chartIconsGreen {
  color: #53b096 !important;
}

.cardShadow {
  box-shadow: 3px 6px lightgrey;
}

.cardHover:hover {
  /* color: #759FBC !important; */
  cursor: pointer !important;
  box-shadow: 5px 8px darkgrey;
}

.chartIconhover:hover {
  /* color: #759FBC !important; */
  cursor: pointer !important;
  font-size: 20px;
}

.scrollSettings {
  white-space: nowrap;
  overflow-x: auto;
}

.marginAdjust {
  margin-top: 0px;
}

@media (max-width: 1024px) {
  .marginAdjust {
    margin-top: -128px;
  }
}
@media (min-width: 280px) and (max-width: 515px) {
  .marginAdjust {
    margin-top: 6px;
  }
}

#yearBtn:disabled {
  background-color: darkgrey;
}

#allTimeBtn:disabled {
  background-color: darkgrey;
}

#LogslistScroll {
  max-height: 300px;
  overflow-y: scroll;
  min-height: 7rem;
}
#EmailLogginglistScroll {
  max-height: 300px;
  overflow-y: scroll;
  min-height: 7rem;
}
.loggingLoading {
  position: relative;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 2%;
}
.mainLoader {
  position: relative;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 2%;
}
.popOverCol {
  text-align: right;
}
.childTextFields {
  padding-left: 1.6rem;
}
