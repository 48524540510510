.w12-5 {
  width: 12.5%;
}

.w17-5 {
  width: 17.5%;
}

.w6 {
    width: 6%;
}

.w15 {
    width: 15%;
}

.import-table .w17-5,
.import-table .w6,
.import-table .w15,
.import-table .w12-5 {
    display: inline-block;
}

.import-table .top-th.dark-gray {
  border: 1px solid #4f4e4e;
  background: #4f4e4e;
  padding-right: 14px;
}

.import-table .dark-gray .td {
  color: white;
  font-weight: 500;
}

.top-th.red-white {
  background: #ff0c0c;
  color: white;
  font-weight: 500;
  cursor: pointer;
  z-index: 200;
}

.top-th.yellow-black {
  background: #ffe500 !important;
  font-weight: 500;
  color: #3f3b3b;
  cursor: pointer;
  z-index: 200;
  position: relative;
}

.icons-file i {
  z-index: 300;
  position: relative;
  font-size: 18px;
  margin-top: 1px;
}

.top-th.light-gray {
  background: #5e5e5e;
  color: #f6f6f6;
  font-weight: 500;
  cursor: pointer;
  z-index: 200;
  position: relative;
}

.w93 {
  width: 95%;
  display: inline-block;
}

.w7 {
  display: inline-block;
  width: 5%;
  white-space: nowrap;
}
.w7.icons-file {
  text-align: right;
  padding-right: 2px;
}
.w7.icons-file a.text-white,
.w7.icons-file .text-dark {
  padding: 0 1.5px;
}
.import-table .td {
  padding: 0px 10px;
}

.light-white {
  cursor: pointer;
}

.import-table .light-white .td {
  border-right: 1.5px solid #938888;
  font-size: 12px;
  font-weight: 400;
  color: #6a6464;
}

.import-table .light-white .td:last-child {
  border-right: 0px solid #938888;
}

.top-td.light-white {
  border-left: 1px solid #938888;
  border-right: 1px solid #938888;
  border-bottom: 1px solid #938888;
  display: flex;
  flex-wrap: wrap;
}

.top-th.yellow-black,
.red-white,
.light-gray {
  border-right: 1px solid #938888;
  border-left: 1px solid gray;
  border-bottom: 1px solid #938888;
}

.w80 {
  display: inline-block;
  width: 80%;
}

.w20 {
  display: inline-block;

  width: 20%;
}

.yellow-black .td {
  font-weight: 600;
  color: #575555;
}

.w20.icons-file {
  text-align: right;
  padding-right: 10px;
  font-size: 17px;
}

.w20.icons-file i {
  margin: 0 2px;
  cursor: pointer;
}

div.scroll-view {
  max-height: 350px;
  overflow: auto;
  overflow-x: hidden;
}

.w100 {
  width: 100%;
}

.rowdiv {
  display: flex;
  flex-wrap: wrap;
}

.width35 {
  width: 35%;
  flex: 0 0 35%;
}

p.infoError {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

h6.error-i {
  padding-left: 45px;
  font-size: 14px;
  color: #ff0c0c;
}

h6.error-i .fa-pen {
  color: #4f4e4e;
  font-size: 12px;
  margin: 0 2px;
}

h6.error-i i {
  font-size: 12px;
}

.pdl-45 {
  padding-left: 45px;
}

p.infoError span {
  font-weight: 200;
  color: #696969;
}

.bdr-bottom {
  border-bottom: 1px solid #938888;
}

h6.ignore-i {
  font-size: 14px;
}

.ignore-i i {
  font-size: 12px;
  margin: 0 2px;
}

.ignore-i .fa-trash {
  color: #ff0c0c;
}

.width25 {
  width: 25%;
  flex: 0 0 25%;
}
.width40 {
  width: 40%;
  flex: 0 0 40%;
}
.width20 {
  width: 20%;
  flex: 0 0 20%;
}
.width15 {
  width: 15%;
  flex: 0 0 15%;
}
.tablehead {
  display: flex;
  flex-wrap: wrap;
  background: #5e5e5e;
  color: white;
  padding: 1px 8px;
  font-size: 12px;
  border: 1px solid #c5c0c0;
}

.tr-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px;
  font-size: 12px;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
}

.tr-body .td {
  border-right: 1px solid gray;
}

.tr-body .td:last-child {
  border-right: 0px solid gray;
}

.tr-body .bold-dark {
  font-weight: 500;
}

@media (max-width: 785px) {
  .import-table .dark-gray .td {
    white-space: nowrap;
  }

  .import-table {
    overflow-x: scroll;
  }

  .top-th.dark-gray {
    display: table-row;
    white-space: nowrap;
  }

  .top-th.dark-gray .td {
    display: table-cell;
    white-space: nowrap;
  }

  .top-td.light-white .td {
    white-space: initial;
  }

  .top-th.red-white,
  .top-th.yellow-black,
  .top-th.light-gray {
    min-width: 496px;
  }

  .top-td.light-white {
    min-width: 496px;
  }

  .top-td.light-white .td {
    display: table-cell;
  }

  .top-td.light-white .w17-5.td {
    width: 98px;
  }

  div.scroll-view {
    max-height: 190px;
    overflow: auto;
    overflow-x: hidden;
    min-width: 496px;
  }

  .resW99 {
    width: 99px !important;
  }

  .resW99 {
    width: 99px !important;
  }

  .resW72 {
    width: 72px !important;
  }

  .resW65 {
    width: 65px !important;
  }

  .resW85 {
    width: 85px !important;
  }

  .resW31person {
    width: 31% !important;
    flex: 0 0 31%;
  }

  .resW69person {
    width: 69% !important;
    flex: 0 0 69%;
  }

  .respdl-20 {
    padding-left: 20px !important;
  }
}

@media (max-width: 625px) {
  .import-table .dark-gray .td {
    white-space: nowrap;
  }

  .import-table {
    overflow-x: scroll;
  }
}

.errorignoremodel {
  max-width: 900px;
}

.ignore-gray {
  padding: 9px 8px;
  border: 1px solid #b3b3b3;
  background: #c1c2c2;
  cursor: pointer;
}

.ignore-gray h5 {
  margin: 0;
  color: #494949;
  font-size: 16px;
}

select.select-ignore {
  width: 100%;
  border-radius: 100px;
  padding: 0.48rem 0.95rem;
  border: 1px solid #ced4da;
  color: gray;
}

select.select-ignore option {
  padding: 0px 10px !important;
}

.modalinput label {
  margin-bottom: 2px;
  margin-left: 9px;
}

.ignore-table-Change thead.bg-dark th {
  padding: 2px 10px;
  color: white;
  font-size: 13px;
}

.ignore-table-Change td {
  padding: 1px 11px;
  border-right: 1px solid #aeaeae;
}

.ignore-table-Change td:last-child {
  border-right: 0px solid;
  border-left: 0 solid;
  border-bottom: 0 solid;
}

.ignore-table-Change td {
  padding: 3px 11px;
  border-bottom: 1px solid #aeaeae;
  border-right: 1px solid #aeaeae;
}

.ignore-table-Change thead tr {
  border: 1px solid gray;
}

.ignore-table-Change td:first-child {
  border-left: 1px solid #aeaeae;
}

.ignore-delete-edit {
  white-space: nowrap;
}

.ignore-delete-edit .fa-trash {
  color: #ff1919;
  padding-left: 5px;
}

.ignore-delete-edit i {
  padding: 0 0;
  font-size: 12px;
  cursor: pointer;
}

.ignore-table-Change input.form-control {
  font-size: 13px;
}

.plr-2 {
  /* padding: 0 8px; */
  padding: 0.25rem 1.5rem;
}
span.sectionCount {
  font-weight: 400;
  font-size: 11px;
  margin-left: 15px;
  font-style: italic;
  position: relative;
  top: -1px;
}
.payfiledetail h4 {
  font-size: 14px;
  margin: 0;
  text-align: left;
}
.payfiledetail p {
  text-align: left;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.payfiledetail {
  position: relative;
  top: 21px;
}
.top-th {
  padding: 2px 0;
}
.reason-area {
  font-size: 15px !important;
}
