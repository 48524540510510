.card-noti p,
.card-noti-read p {
  margin-bottom: 2px;
}
.card-noti p,
.card-noti-read p {
  font-style: oblique;
}
.noti_Request_link {
  color: #7ac2fa !important;
}
.noti_isread {
  color: #1877f2;
}
