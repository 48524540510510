.md-form label {
    font-size: 0.95rem;
}
.md-form label.active {
    font-size: 0.8rem;
}
.md-form .prefix {
    top: 0.25rem;
    font-size: 1.75rem;
}
.md-form .prefix ~ input,
.md-form .prefix ~ textarea {
    margin-left: 2.5rem;
    width: calc(100% - 2.5rem);
}
.md-form .prefix ~ label {
    margin-left: 2.5rem;
}
.md-form .prefix ~ .form-text {
    margin-left: 2.6rem;
}
.md-form label {
    position: absolute;
    top: 0.65rem;
    left: 0;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    cursor: text;
    color: #979797;
    font-weight: normal;
}
.md-form label.active {
    -webkit-transform: translateY(-140%);
    transform: translateY(-140%);
}
.md-form {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.md-form input[type="text"],
.md-form input[type="password"],
.md-form input[type="email"],
.md-form input[type="url"],
.md-form input[type="time"],
.md-form input[type="date"],
.md-form input[type="datetime-local"],
.md-form input[type="tel"],
.md-form input[type="number"],
.md-form input[type="search-md"],
.md-form input[type="search"],
.md-form textarea.md-textarea {
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    box-sizing: content-box;
    background-color: transparent;
}
.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="email"]:focus:not([readonly]),
.md-form input[type="url"]:focus:not([readonly]),
.md-form input[type="time"]:focus:not([readonly]),
.md-form input[type="date"]:focus:not([readonly]),
.md-form input[type="datetime-local"]:focus:not([readonly]),
.md-form input[type="tel"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form input[type="search"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    box-shadow: 0 1px 0 0 #4285f4;
    border-bottom: 1px solid #4285f4;
}
.md-form input[type="text"]:focus:not([readonly]) + label,
.md-form input[type="password"]:focus:not([readonly]) + label,
.md-form input[type="email"]:focus:not([readonly]) + label,
.md-form input[type="url"]:focus:not([readonly]) + label,
.md-form input[type="time"]:focus:not([readonly]) + label,
.md-form input[type="date"]:focus:not([readonly]) + label,
.md-form input[type="datetime-local"]:focus:not([readonly]) + label,
.md-form input[type="tel"]:focus:not([readonly]) + label,
.md-form input[type="number"]:focus:not([readonly]) + label,
.md-form input[type="search-md"]:focus:not([readonly]) + label,
.md-form input[type="search"]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
    color: #4285f4;
}
.md-form input[type="text"] + label:after,
.md-form input[type="password"] + label:after,
.md-form input[type="email"] + label:after,
.md-form input[type="url"] + label:after,
.md-form input[type="time"] + label:after,
.md-form input[type="date"] + label:after,
.md-form input[type="datetime-local"] + label:after,
.md-form input[type="tel"] + label:after,
.md-form input[type="number"] + label:after,
.md-form input[type="search-md"] + label:after,
.md-form input[type="search"] + label:after,
.md-form textarea.md-textarea + label:after {
    content: "";
    position: absolute;
    top: 65px;
    display: block;
    opacity: 0;
    -webkit-transition: 0.2s opacity ease-out, 0.2s color ease-out;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
}
.md-form input[type="text"].valid,
.md-form input[type="text"]:focus.valid,
.md-form input[type="password"].valid,
.md-form input[type="password"]:focus.valid,
.md-form input[type="email"].valid,
.md-form input[type="email"]:focus.valid,
.md-form input[type="url"].valid,
.md-form input[type="url"]:focus.valid,
.md-form input[type="time"].valid,
.md-form input[type="time"]:focus.valid,
.md-form input[type="date"].valid,
.md-form input[type="date"]:focus.valid,
.md-form input[type="datetime-local"].valid,
.md-form input[type="datetime-local"]:focus.valid,
.md-form input[type="tel"].valid,
.md-form input[type="tel"]:focus.valid,
.md-form input[type="number"].valid,
.md-form input[type="number"]:focus.valid,
.md-form input[type="search-md"].valid,
.md-form input[type="search-md"]:focus.valid,
.md-form input[type="search"].valid,
.md-form input[type="search"]:focus.valid,
.md-form textarea.md-textarea.valid,
.md-form textarea.md-textarea:focus.valid {
    border-bottom: 1px solid #00c851;
    box-shadow: 0 1px 0 0 #00c851;
}
.md-form input[type="text"].valid + label:after,
.md-form input[type="text"]:focus.valid + label:after,
.md-form input[type="password"].valid + label:after,
.md-form input[type="password"]:focus.valid + label:after,
.md-form input[type="email"].valid + label:after,
.md-form input[type="email"]:focus.valid + label:after,
.md-form input[type="url"].valid + label:after,
.md-form input[type="url"]:focus.valid + label:after,
.md-form input[type="time"].valid + label:after,
.md-form input[type="time"]:focus.valid + label:after,
.md-form input[type="date"].valid + label:after,
.md-form input[type="date"]:focus.valid + label:after,
.md-form input[type="datetime-local"].valid + label:after,
.md-form input[type="datetime-local"]:focus.valid + label:after,
.md-form input[type="tel"].valid + label:after,
.md-form input[type="tel"]:focus.valid + label:after,
.md-form input[type="number"].valid + label:after,
.md-form input[type="number"]:focus.valid + label:after,
.md-form input[type="search-md"].valid + label:after,
.md-form input[type="search-md"]:focus.valid + label:after,
.md-form input[type="search"].valid + label:after,
.md-form input[type="search"]:focus.valid + label:after,
.md-form textarea.md-textarea.valid + label:after,
.md-form textarea.md-textarea:focus.valid + label:after {
    content: attr(data-success);
    color: #00c851;
    opacity: 1;
}
.md-form input[type="text"].invalid,
.md-form input[type="text"]:focus.invalid,
.md-form input[type="password"].invalid,
.md-form input[type="password"]:focus.invalid,
.md-form input[type="email"].invalid,
.md-form input[type="email"]:focus.invalid,
.md-form input[type="url"].invalid,
.md-form input[type="url"]:focus.invalid,
.md-form input[type="time"].invalid,
.md-form input[type="time"]:focus.invalid,
.md-form input[type="date"].invalid,
.md-form input[type="date"]:focus.invalid,
.md-form input[type="datetime-local"].invalid,
.md-form input[type="datetime-local"]:focus.invalid,
.md-form input[type="tel"].invalid,
.md-form input[type="tel"]:focus.invalid,
.md-form input[type="number"].invalid,
.md-form input[type="number"]:focus.invalid,
.md-form input[type="search-md"].invalid,
.md-form input[type="search-md"]:focus.invalid,
.md-form input[type="search"].invalid,
.md-form input[type="search"]:focus.invalid,
.md-form textarea.md-textarea.invalid,
.md-form textarea.md-textarea:focus.invalid {
    border-bottom: 1px solid #f44336;
    box-shadow: 0 1px 0 0 #f44336;
}
.md-form input[type="text"].invalid + label:after,
.md-form input[type="text"]:focus.invalid + label:after,
.md-form input[type="password"].invalid + label:after,
.md-form input[type="password"]:focus.invalid + label:after,
.md-form input[type="email"].invalid + label:after,
.md-form input[type="email"]:focus.invalid + label:after,
.md-form input[type="url"].invalid + label:after,
.md-form input[type="url"]:focus.invalid + label:after,
.md-form input[type="time"].invalid + label:after,
.md-form input[type="time"]:focus.invalid + label:after,
.md-form input[type="date"].invalid + label:after,
.md-form input[type="date"]:focus.invalid + label:after,
.md-form input[type="datetime-local"].invalid + label:after,
.md-form input[type="datetime-local"]:focus.invalid + label:after,
.md-form input[type="tel"].invalid + label:after,
.md-form input[type="tel"]:focus.invalid + label:after,
.md-form input[type="number"].invalid + label:after,
.md-form input[type="number"]:focus.invalid + label:after,
.md-form input[type="search-md"].invalid + label:after,
.md-form input[type="search-md"]:focus.invalid + label:after,
.md-form input[type="search"].invalid + label:after,
.md-form input[type="search"]:focus.invalid + label:after,
.md-form textarea.md-textarea.invalid + label:after,
.md-form textarea.md-textarea:focus.invalid + label:after {
    content: attr(data-error);
    color: #f44336;
    opacity: 1;
}
.md-form input[type="text"].form-control.valid + label:after,
.md-form input[type="text"].form-control:focus.valid + label:after,
.md-form input[type="password"].form-control.valid + label:after,
.md-form input[type="password"].form-control:focus.valid + label:after,
.md-form input[type="email"].form-control.valid + label:after,
.md-form input[type="email"].form-control:focus.valid + label:after,
.md-form input[type="url"].form-control.valid + label:after,
.md-form input[type="url"].form-control:focus.valid + label:after,
.md-form input[type="time"].form-control.valid + label:after,
.md-form input[type="time"].form-control:focus.valid + label:after,
.md-form input[type="date"].form-control.valid + label:after,
.md-form input[type="date"].form-control:focus.valid + label:after,
.md-form input[type="datetime-local"].form-control.valid + label:after,
.md-form input[type="datetime-local"].form-control:focus.valid + label:after,
.md-form input[type="tel"].form-control.valid + label:after,
.md-form input[type="tel"].form-control:focus.valid + label:after,
.md-form input[type="number"].form-control.valid + label:after,
.md-form input[type="number"].form-control:focus.valid + label:after,
.md-form input[type="search-md"].form-control.valid + label:after,
.md-form input[type="search-md"].form-control:focus.valid + label:after,
.md-form input[type="search"].form-control.valid + label:after,
.md-form input[type="search"].form-control:focus.valid + label:after,
.md-form textarea.md-textarea.form-control.valid + label:after,
.md-form textarea.md-textarea.form-control:focus.valid + label:after {
    top: 3.56rem;
}
.md-form input[type="text"].form-control.invalid + label:after,
.md-form input[type="text"].form-control:focus.invalid + label:after,
.md-form input[type="password"].form-control.invalid + label:after,
.md-form input[type="password"].form-control:focus.invalid + label:after,
.md-form input[type="email"].form-control.invalid + label:after,
.md-form input[type="email"].form-control:focus.invalid + label:after,
.md-form input[type="url"].form-control.invalid + label:after,
.md-form input[type="url"].form-control:focus.invalid + label:after,
.md-form input[type="time"].form-control.invalid + label:after,
.md-form input[type="time"].form-control:focus.invalid + label:after,
.md-form input[type="date"].form-control.invalid + label:after,
.md-form input[type="date"].form-control:focus.invalid + label:after,
.md-form input[type="datetime-local"].form-control.invalid + label:after,
.md-form input[type="datetime-local"].form-control:focus.invalid + label:after,
.md-form input[type="tel"].form-control.invalid + label:after,
.md-form input[type="tel"].form-control:focus.invalid + label:after,
.md-form input[type="number"].form-control.invalid + label:after,
.md-form input[type="number"].form-control:focus.invalid + label:after,
.md-form input[type="search-md"].form-control.invalid + label:after,
.md-form input[type="search-md"].form-control:focus.invalid + label:after,
.md-form input[type="search"].form-control.invalid + label:after,
.md-form input[type="search"].form-control:focus.invalid + label:after,
.md-form textarea.md-textarea.form-control.invalid + label:after,
.md-form textarea.md-textarea.form-control:focus.invalid + label:after {
    top: 3.44rem;
}
.md-form input[type="text"].form-control-lg.valid + label:after,
.md-form input[type="text"].form-control-lg:focus.valid + label:after,
.md-form input[type="password"].form-control-lg.valid + label:after,
.md-form input[type="password"].form-control-lg:focus.valid + label:after,
.md-form input[type="email"].form-control-lg.valid + label:after,
.md-form input[type="email"].form-control-lg:focus.valid + label:after,
.md-form input[type="url"].form-control-lg.valid + label:after,
.md-form input[type="url"].form-control-lg:focus.valid + label:after,
.md-form input[type="time"].form-control-lg.valid + label:after,
.md-form input[type="time"].form-control-lg:focus.valid + label:after,
.md-form input[type="date"].form-control-lg.valid + label:after,
.md-form input[type="date"].form-control-lg:focus.valid + label:after,
.md-form input[type="datetime-local"].form-control-lg.valid + label:after,
.md-form input[type="datetime-local"].form-control-lg:focus.valid + label:after,
.md-form input[type="tel"].form-control-lg.valid + label:after,
.md-form input[type="tel"].form-control-lg:focus.valid + label:after,
.md-form input[type="number"].form-control-lg.valid + label:after,
.md-form input[type="number"].form-control-lg:focus.valid + label:after,
.md-form input[type="search-md"].form-control-lg.valid + label:after,
.md-form input[type="search-md"].form-control-lg:focus.valid + label:after,
.md-form input[type="search"].form-control-lg.valid + label:after,
.md-form input[type="search"].form-control-lg:focus.valid + label:after,
.md-form textarea.md-textarea.form-control-lg.valid + label:after,
.md-form textarea.md-textarea.form-control-lg:focus.valid + label:after {
    top: 4.1rem;
}
.md-form input[type="text"].form-control-lg.invalid + label:after,
.md-form input[type="text"].form-control-lg:focus.invalid + label:after,
.md-form input[type="password"].form-control-lg.invalid + label:after,
.md-form input[type="password"].form-control-lg:focus.invalid + label:after,
.md-form input[type="email"].form-control-lg.invalid + label:after,
.md-form input[type="email"].form-control-lg:focus.invalid + label:after,
.md-form input[type="url"].form-control-lg.invalid + label:after,
.md-form input[type="url"].form-control-lg:focus.invalid + label:after,
.md-form input[type="time"].form-control-lg.invalid + label:after,
.md-form input[type="time"].form-control-lg:focus.invalid + label:after,
.md-form input[type="date"].form-control-lg.invalid + label:after,
.md-form input[type="date"].form-control-lg:focus.invalid + label:after,
.md-form input[type="datetime-local"].form-control-lg.invalid + label:after,
.md-form
    input[type="datetime-local"].form-control-lg:focus.invalid
    + label:after,
.md-form input[type="tel"].form-control-lg.invalid + label:after,
.md-form input[type="tel"].form-control-lg:focus.invalid + label:after,
.md-form input[type="number"].form-control-lg.invalid + label:after,
.md-form input[type="number"].form-control-lg:focus.invalid + label:after,
.md-form input[type="search-md"].form-control-lg.invalid + label:after,
.md-form input[type="search-md"].form-control-lg:focus.invalid + label:after,
.md-form input[type="search"].form-control-lg.invalid + label:after,
.md-form input[type="search"].form-control-lg:focus.invalid + label:after,
.md-form textarea.md-textarea.form-control-lg.invalid + label:after,
.md-form textarea.md-textarea.form-control-lg:focus.invalid + label:after {
    top: 4rem;
}
.md-form input[type="text"].form-control-sm.valid + label:after,
.md-form input[type="text"].form-control-sm:focus.valid + label:after,
.md-form input[type="password"].form-control-sm.valid + label:after,
.md-form input[type="password"].form-control-sm:focus.valid + label:after,
.md-form input[type="email"].form-control-sm.valid + label:after,
.md-form input[type="email"].form-control-sm:focus.valid + label:after,
.md-form input[type="url"].form-control-sm.valid + label:after,
.md-form input[type="url"].form-control-sm:focus.valid + label:after,
.md-form input[type="time"].form-control-sm.valid + label:after,
.md-form input[type="time"].form-control-sm:focus.valid + label:after,
.md-form input[type="date"].form-control-sm.valid + label:after,
.md-form input[type="date"].form-control-sm:focus.valid + label:after,
.md-form input[type="datetime-local"].form-control-sm.valid + label:after,
.md-form input[type="datetime-local"].form-control-sm:focus.valid + label:after,
.md-form input[type="tel"].form-control-sm.valid + label:after,
.md-form input[type="tel"].form-control-sm:focus.valid + label:after,
.md-form input[type="number"].form-control-sm.valid + label:after,
.md-form input[type="number"].form-control-sm:focus.valid + label:after,
.md-form input[type="search-md"].form-control-sm.valid + label:after,
.md-form input[type="search-md"].form-control-sm:focus.valid + label:after,
.md-form input[type="search"].form-control-sm.valid + label:after,
.md-form input[type="search"].form-control-sm:focus.valid + label:after,
.md-form textarea.md-textarea.form-control-sm.valid + label:after,
.md-form textarea.md-textarea.form-control-sm:focus.valid + label:after {
    top: 3.2rem;
}
.md-form input[type="text"].form-control-sm.invalid + label:after,
.md-form input[type="text"].form-control-sm:focus.invalid + label:after,
.md-form input[type="password"].form-control-sm.invalid + label:after,
.md-form input[type="password"].form-control-sm:focus.invalid + label:after,
.md-form input[type="email"].form-control-sm.invalid + label:after,
.md-form input[type="email"].form-control-sm:focus.invalid + label:after,
.md-form input[type="url"].form-control-sm.invalid + label:after,
.md-form input[type="url"].form-control-sm:focus.invalid + label:after,
.md-form input[type="time"].form-control-sm.invalid + label:after,
.md-form input[type="time"].form-control-sm:focus.invalid + label:after,
.md-form input[type="date"].form-control-sm.invalid + label:after,
.md-form input[type="date"].form-control-sm:focus.invalid + label:after,
.md-form input[type="datetime-local"].form-control-sm.invalid + label:after,
.md-form
    input[type="datetime-local"].form-control-sm:focus.invalid
    + label:after,
.md-form input[type="tel"].form-control-sm.invalid + label:after,
.md-form input[type="tel"].form-control-sm:focus.invalid + label:after,
.md-form input[type="number"].form-control-sm.invalid + label:after,
.md-form input[type="number"].form-control-sm:focus.invalid + label:after,
.md-form input[type="search-md"].form-control-sm.invalid + label:after,
.md-form input[type="search-md"].form-control-sm:focus.invalid + label:after,
.md-form input[type="search"].form-control-sm.invalid + label:after,
.md-form input[type="search"].form-control-sm:focus.invalid + label:after,
.md-form textarea.md-textarea.form-control-sm.invalid + label:after,
.md-form textarea.md-textarea.form-control-sm:focus.invalid + label:after {
    top: 3.1rem;
}
.md-form .was-validated input[type="text"]:valid + label {
    color: #00c851 !important;
}
.md-form .was-validated input[type="text"]:invalid + label {
    color: #f44336 !important;
}
.md-form .was-validated .form-control:valid:focus {
    box-shadow: 0 1px 0 0 #00c851 !important;
}
.md-form .was-validated .form-control:valid {
    border-color: #00c851 !important;
}
.md-form .was-validated .form-control:invalid:focus {
    box-shadow: 0 1px 0 0 #f44336 !important;
}
.md-form .was-validated .form-control:invalid {
    border-color: #f44336 !important;
}
.md-form .form-control {
    margin: 0 0 0.5rem 0;
    border-radius: 0;
    padding: 0.3rem 0 0.55rem 0;
    background-image: none;
    background-color: transparent;
    height: auto;
}
.md-form .form-control:focus {
    box-shadow: none;
    background: transparent;
}
.md-form .form-control:disabled,
.md-form .form-control[readonly] {
    border-bottom: 1px solid #bdbdbd;
    background-color: transparent;
}
.md-form .validate {
    margin-bottom: 2.5rem;
}
.md-accordion .card .card-header {
    border-bottom-color: #ccc;
    padding: 1rem 1.5rem 0.8rem 0;
    background: 0 0;
}
.md-accordion .card {
    border: none;
    box-shadow: none;
}
