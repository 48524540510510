.permission-nav {
    margin-bottom: 15px;
    background-color: #ececf1 !important;
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    top: 70px;
    width: 100%;
    z-index: 1;
}
 
@media(max-width:1024px){
    .permission-nav {
        top:116px;
    }
}
.p-tabs{
    padding: 7px 4px;
    
}
@media(min-width:537px){
    .p-tabs{
        font-size:15px;
        margin-right:17px
    }
}
    .p-tabs.active {
        color: #759FBC !important;
        border-bottom: 3px solid #759FBC !important;
    
    }
