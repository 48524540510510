/* If the screen size is 600px or less */
@media only screen and (min-width:250px) and (max-width: 576px) {
    
    .stepNumber{
        font-size: 10px !important;
    }
    .stepDetail{
        font-size: 7px !important;
    }
    .box .steps ul li.active div {
        border-color: #167495 !important;
        color: #fff !important;
        background: #167495 !important;
        width: auto !important;
    }

    .box .steps ul li div {
        color: #999;
        padding: 10px 0 15px 45px;
        position: relative;
        background: #f5f5f5;
        width: auto !important;
    }

    .box .steps ul li.done div {
        border-color: #20a8d8 !important;
        color: #fff !important;
        background: #20a8d8 !important;
        width: auto !important;
    }
}
@media only screen and (min-width:576px) and (max-width: 768px) {

    .stepNumber {
        font-size: 11px !important;
    }

    .stepDetail {
        font-size: 8px !important;
    }

    .box .steps ul li.active div {
        border-color: #167495 !important;
        color: #fff !important;
        background: #167495 !important;
        width: auto !important;
    }

    .box .steps ul li div {
        color: #999;
        padding: 10px 0 15px 45px;
        position: relative;
        background: #f5f5f5;
        width: auto !important;
    }

    .box .steps ul li.done div {
        border-color: #20a8d8 !important;
        color: #fff !important;
        background: #20a8d8 !important;
        width: auto !important;
    }
}
@media only screen and (min-width:768px) and (max-width: 992px) {

    .stepNumber {
        font-size: 12px !important;
    }

    .stepDetail {
        font-size: 9px !important;
    }

    .box .steps ul li.active div {
        border-color: #167495 !important;
        color: #fff !important;
        background: #167495 !important;
        width: auto !important;
    }

    .box .steps ul li div {
        color: #999;
        padding: 10px 0 15px 45px;
        position: relative;
        background: #f5f5f5;
        width: auto !important;
    }

    .box .steps ul li.done div {
        border-color: #20a8d8 !important;
        color: #fff !important;
        background: #20a8d8 !important;
        width: auto !important;
    }
}
.box {
    width: auto;
    border: 1px solid #ddd;
    min-height: 65vh;
}

.box .steps {
    border-bottom: 1px solid #ddd;
}

.box .steps ul {
    overflow: hidden;
}

.box .steps ul li div {
    color: #999;
    padding: 10px 0 15px 45px;
    position: relative;
    background: #f5f5f5;
    width: 165px;   
}

.box .steps ul li div span {
    font-size: 13px;
}

.box .steps ul li:first-child div {
    width: 135px;
    padding-left: 15px;
}

.box .steps ul li div::before {
    content: " ";
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #ddd;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 1;
    margin-left: 1px;
}

.box .steps ul li div::after {
    content: " ";
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #f5f5f5;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
}

.box .steps ul li.done div {
    border-color: #20a8d8 !important;
    color: #fff !important;
    background: #20a8d8 !important;
}

.box .steps ul li.done div::after {
    border-left: 30px solid #20a8d8;
}

.box .steps ul li.active div {
    border-color: #167495 !important;
    color: #fff !important;
    background: #167495 !important;
}

.box .steps ul li.active div::after {
    border-left: 30px solid #167495;
}

.box .step-component {
    padding: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box .btn-component {
    padding: 20px;
    display: flex;
    justify-content: space-between;
} 