.content>.pac-container,
.pac-container {
  margin-top: 110px !important;
}

@media (max-width: 1024px) {

  .content>.pac-container,
  .pac-container {
    margin-top: 145px !important;
  }
}

@media (max-width: 527px) {

  .content>.pac-container,
  .pac-container {
    padding: 5px !important;
    margin-top: 160px !important;
  }
}

.content>.pac-container .bg-light-blue {
  background-color: rgba(186, 236, 255, 0.59);
}

.pac-form input::-webkit-input-placeholder {
  color: #ccc !important;
  font-style: italic;
}

.pac-form input:focus+label,
.pac-form textarea:focus+label {
  color: #4285f4;
}

.pac-form .md-form .prefix {
  position: absolute;
}

#flyer-uploader .filepond--root {
  overflow-y: hidden !important;
}

.candidateActionSpan:hover {
  cursor: pointer;
}

.historyheight {
  max-height: 200px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  word-wrap: break-word !important;
}

/* textarea:-moz-placeholder, */
/* Firefox 18- */
/* textarea::-moz-placeholder, */
/* Firefox 19+ */
/* textarea:-ms-input-placeholder, */
/* IE 10+ */
/* textarea::-webkit-input-placeholder, */
/* Webkit based */
/* textarea::placeholder  */

.textareaPlaceholder::placeholder {
  /* Modern browsers */
  color: #e6e6e6;
  font-style: italic;
}