.FilterSearch {
  background: #696969;
}

.btn {
  border-radius: 14px;
  font-size: 14px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.displayInline {
  display: flex;
}

hr {
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.borderLine {
  border-bottom: 1px solid #e9ecef;
}
.filterSelected {
  display: flex;
  /* border: 0; */
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-left: 1vh;
  cursor: pointer;
}

.filterSelectedMobile {
  display: flex;
  /* border: 0; */
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.collapsBtn {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #686e82;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 4px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}
.collapseChildTab {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #686e82;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.collapsBtnFirst {
  width: 100%;
  border-radius: 0;
  padding: 11px 20px;
  background: #686e82;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 0 0 2px 0 !important;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.mobileCollapsBtn {
  width: 100%;
  border-radius: 0;
  padding: 8px 14px;
  background: #759fbc;
  text-align: left;
  font-size: 12px;
  font-weight: 450;
  letter-spacing: 0.9px;
  border: none;
  transition: background-color 1s ease;
  color: white;
  cursor: pointer;
  /* margin: 4px 0; */
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.arrow-icon {
  font-size: 12px;
  margin-top: 5px;
  transition: 0.5s ease all;
}

.collapsBtn:hover {
  background: #dd333d;
}

.AdvanceFilterModal a.active.nav-link {
  color: white;
}

.AdvanceFilterModal a.active.nav-link {
  background: #759fbc;
}

.AdvanceFilterModal a.nav-link {
  cursor: pointer;
  transition: 0.5s ease all;
}

.totalCountMmeber {
  font-size: 13px;
  color: #222222;
  font-style: italic;
  margin-right: 13px;
}
.MobiletotalCountMmeber {
  font-size: 13px;
  color: #222222;
  font-style: italic;
  text-align: center;
}

.spac-remove {
  padding: 0px;
  margin-left: -1px;
  border: 1px solid #e5e5e5;
}

.bottom-stick {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.apply-btn-wrap {
  position: absolute;
  left: 0;
  bottom: 50px;
  right: 10px;
}
/* html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
} */
.collapsParentAccord {
  border-radius: 0;
  padding: 9px 10px;
  background: #cb2630;
  text-align: left;
  font-size: 80%;
  font-weight: 450;
  letter-spacing: 0.9px;
  border: none;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.collapsParentAccordActive {
  border-radius: 0;
  padding: 10px 10px;
  background: #cb2630;
  text-align: left;
  font-size: 80%;
  font-weight: 450;
  letter-spacing: 0.9px;
  border: none;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.tabSection {
  width: 100%;
  border-radius: 0;
  padding: 8px 14px;
  background: #333547;
  text-align: left;
  font-size: 80%;
  font-weight: 425;
  letter-spacing: 0.7px;
  border: none;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  color: white;
  cursor: pointer;
  margin: 1px 0;
  vertical-align: middle;
  justify-content: space-between;
  display: flex;
}

.mobileSection {
  background: #333547;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0;
  padding-left: 0;
}

.form-group > label {
  font-weight: 500;
}
/* .parentMobileDiv>.collapse > div {
  background: #333547;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
} */

.parentMobileDiv > .collapse > .card-body:nth-child(1) {
  background: #ced4da !important;
}
.parentMobileDiv > .collapse > .card-body:nth-child(1) > div {
  background: #333547 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
/* .card-body > :first-child {
  background: yellow;
} */
/* .parentMobileDiv>.collapse  {
  background: #cb2630 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
} */

/* .parentMobileDiv > div > .collapse > form > .row > .col > span > label {
  color: white !important;
} */

.textAlignRight {
  text-align: end;
}

/* .parentMobileDiv
  > div
  > .collapse
  > form
  > .row
  > .col
  > .custom-control
  > label {
  color: white !important;
} */

/*
.parentMobileDiv{
  max-height:calc(100vh - 370px);
  overflow-y: auto;
  background: #ced4da !important;
} */

/* .modal {
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  background: blue !important; 
  overflow-y: scroll; 
} */
.mobile-modal-dialog {
  /* display: flex;
  width: auto;
  height: 70vh;
  height: calc((var(--vh, 1vh) * -569));*/
  /* background: green !important; */
  max-width: 99%;
}
/* .modal-content {
  height: 100vh;
  height: calc((var(--vh, 1vh) * 260)); 
   background: purple !important;
} */
.btn-filter-sm {
  font-size: 11.66667px !important;
  border-radius: 1.8vh;
}
.customMobileHeader {
  max-height: calc((var(--vh, 1vh) * 15));
}
.modal-body-custom {
  max-height: calc((var(--vh, 1vh) * 100));
  /* background: orange !important; */
}

.parentMobileDiv {
  /* height: calc((var(--vh, 1vh) * 40)); */
  max-height: calc((var(--vh, 1vh) * 35));
  background: #ced4da !important;
  overflow-y: scroll;
}
.mobFilterSpan {
  max-height: calc((var(--vh, 1vh) * 10));
}
.tagsScroll {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc((var(--tagsScroll, 1vh) * 10));
  /* display: flex; */
}

.footerBtns {
  max-height: calc((var(--vh, 1vh) * 23));
  overflow: auto;
  overflow-x: hidden;
}

.removeYScroll {
  overflow: hidden;
  /* position: fixed; */
}
.headerMobileColor {
  color: white !important;
}
.parentMobileDiv > div > .tabSection > a {
  margin-right: auto;
}

.parentMobileDiv > div > .mobileCollapsBtn > a {
  margin-right: auto;
}
.mobileHeaderBtns > button {
  margin-right: 1.2vw;
  border-radius: 1.8vh !important;
  /* margin: 0.5vw; */
}

.modalScroll {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
}
/* When the screen width is 1100 pixels or larger. */
@media (min-width: 1100px) {
  .modalDefaultHeight {
    min-height: 45rem;
  }
}
.smallFont {
  font-size: 88%;
  font-weight: 400;
}

.mobileModalHeader {
  display: block;
}
.mobileSideBarResults {
  color: white;
  font-size: 2.8vw;
}
.btn-apply {
  background: #759fbc;
}
.webHeader {
  color: white;
  background: #759fbc;
  padding: 12px 15px;
  margin: 0 -1px;
}

.dynamicCheckBoxLayout {
  display: grid;
}

.fatrashBtn {
  background-color: #759fbc;
  height: calc(2rem + 1px) !important;
  color: white;
  border-radius: 0;
}
.faAddBtn {
  background-color: #759fbc;
  height: calc(2rem + 1px) !important;
  color: white;
  border-radius: 0;
  height: fit-content;
}
.modal-dialog-margin {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
ul {
  list-style-type: none;
  padding-left: 0;
  display: inline;
}

.displayFlex {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 760px) {
  .advancefilterMT {
    color: white;
  }
}

.AdvanceFilterModal .modal-header .close {
  padding: 1rem 1rem;
  margin: 0;
  color: white;
}

.chechbox-list {
  -webkit-columns: 150px;
  -moz-columns: 150px;
  columns: 150px;
  -webkit-column-gap: 2em;
  -moz-column-gap: 2em;
  column-gap: 5em;
  margin: 0;
}

.chechbox-list li.chechbox-list_item {
   display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox; 
  display: flex;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  padding: 4px 0;
}
.filter-fields-scroll {
    max-height: calc(100vh - 65vh);
    overflow: auto;
    display: flex;
    padding: 18px 20px;
}
.fe-filter--scroll {
    max-height: calc(100vh - 65vh);
    overflow: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    padding: 18px 20px;
    flex-wrap: wrap;
    flex-direction: column;
}
.fe-filter--scroll-mobile {
    max-height: calc(100vh - 65vh);
    overflow: auto;     
    padding: 18px 20px; 
}
.height-scroll {
  height: 35px;
}
 
.header-back {
  background: #759fbc;
  margin-top: -1px;
}
.AdvanceFilterModal .custom-checkbox label.custom-control-label {
  font-size: 15px;
  letter-spacing: 0.5px;
}
.borderNone {
  border: none;
  margin: -1px;
}
.applyfilter {
  float: right;
  /* margin-bottom: 8px; */
}
.apply-filter-selection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.mobileHeadBtns {
  padding-right: 0.5rem;
}

.filter-selected {
  background: #e6e6e6;
  padding: 5px 12px;
  margin-left: 10px;
  border-radius: 6px;
  display: inline-block;
  margin-bottom: 8px;
  cursor: pointer;
}
.filter-selected a {
  color: #4a4a4a;
  margin-left: 10px;
  cursor: pointer;
}
.memberNumId {
  border-radius: 0;
}

.footerBtns {
  border-top: 0;
}

.mobileUIFilter {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.field-array-scroll {
  max-height: calc(100vh - 400px);
  overflow: auto;
  display: flex;
  padding-left: 20px;
}
.mobileFilterSelectSpan {
  align-items: center;
}

.mobFilterSpan {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.bottomBorderMobile {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.memberpage-tags {
  padding: 0 0 5px 12px;
}
.memberpage-tags .filter-selected {
  background: #e0e0ea;
  padding: 8px 15px;
  font-size: 15px;
  border-radius: 10px;
}
.feequip-tags {
  padding: 1rem 0 0 1.3rem;
}
.feequip-tags .filter-selected {
  background: #e0e0ea;
  padding: 8px 15px;
  font-size: 15px;
  border-radius: 10px;
}
.filtersBorder {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.setTagsPadding {
  padding-right: 0;
  padding-left: 2.6vh;
  width: 100%;
  margin-right: 0;
  position: relative;
  left: 3px;
}
.setFETagsPadding {
  padding-right: 0;
  padding-left: 2.6vh;
  width: 100%;
  margin-right: 0;
  position: relative;
  left: 3px;
}
.totalCount {
  padding-left: 0.5vh;
}
.ButtonsRadius > .btn {
  border-radius: 1.8vh !important;
}

.ButtonsRadius > .btn {
  border-radius: 1.8vh !important;
}

.custom-control-input {
  position: relative;
  z-index: -1;
  opacity: 0;
}

.spinnerPosition {
  position: absolute;

  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;

  background-size: 100%;
}

.spinner-grow-sm {
  width: 1rem;
  height: 0.6rem;
}
.longTextchbx {
  -webkit-columns: 215px;
  -moz-columns: 215px;
  columns: 215px;
}
.longTextBudgetLineChbx {
  -webkit-columns: 350px;
  -moz-columns: 350px;
  columns: 350px;
}
.subheading-chks {
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  margin: 0 0 5px 5px;
}
.address-padding {
  padding: 20px 20px 0 20px;
}
.ad-range-box {
  padding: 0 40px 0 20px;
}
.range-slider {
  padding: 0 8px;
}
span.input-range__label-container {
  font-size: 16px;
  color: black;
  font-family: inherit;
  letter-spacing: 1.2px;
}
/* 
.input-range__slider {
  background: #759fbc;
  border: 1px solid #759fbc;
}
.input-range__track--active {
  background: #759fbc;
} */

.input-range__label--value {
  top: -2.2rem !important;
}
.input-range__label--min,
.input-range__label--max {
  top: 1.5rem;
}
.search-ad {
  padding: 12px 15px 0 15px;
}

.parentMobileDiv .row {
  margin: 0;
}
.parentMobileDiv span.input-range__label-container {
  color: white;
}
.parentMobileDiv .ad-range-box {
  padding: 0 40px 20px 20px;
}

/* Advanced filter save search controls */
.advanced-search-container input,
.advanced-search-container button {
  min-height: 36px !important;
}
.advanced-search-container .btn-info2 {
  cursor: pointer;
  background: #759fbc !important;
  color: #ffffff;
}
.advanced-search-container button + div.dropdown {
  margin-left: 8px !important;
}
.advanced-search-container
  .dropdown-menu
  div.accordion
  div.card
  div.card-header {
  padding: 4px 4px 4px 4px !important;
}
.advanced-search-container .dropdown-menu div.accordion div.card {
  margin-bottom: 0px !important;
}
.advanced-search-container .dropdown-menu.show {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.advanced-search-container
  div.dropdown
  div.accordion
  div.card
  div.card-header
  button.btn-link {
  text-decoration: none !important;
}
.advanced-search-container div.dropdown div.accordion div.card-header:hover {
  background-color: #028b81 !important;
  color: white !important;
}
.advanced-search-container
  div.dropdown
  div.accordion
  div.card
  button.btn-link:hover {
  color: white !important;
}
.advanced-search-container div.dropdown div.accordion div.card div.card-body {
  padding: 4px 4px 4px 24px !important;
  background-color: #ececf1 !important;
  font-size: 14px;
}
.advanced-search-container div.dropdown div.dropdown-menu.show {
  min-width: 14rem !important;
  /* min-height: 50vh !important; */
  height: auto !important;
}
.advanced-search-container div.card-body ul li {
  padding-top: 4px !important;
}
.advanced-search-container div.card-body ul li + li {
  border-top: dashed 1px #5b626b !important;
}
.advanced-search-container div.card {
  /* border-bottom: solid 1px #5b626b !important; */
}
.advanced-search-container div.dropdown-menu.show {
  /* border-top: solid 1px #5b626b !important;
  border-right: solid 1px #5b626b !important;
  border-left: solid 1px #5b626b !important; */
  border: solid 1px #5b626b !important;
  /* z-index: 1000 !important; */
}
.advanced-search-container div.card-body ul li,
.advanced-search-container div.card-body li a {
  color: #5b626b !important;
}
.advanced-search-container div.card-body ul li a:hover,
.advanced-search-container div.card-body ul li i[class*="fa"]:hover {
  color: #759fbc !important;
  cursor: pointer !important;
}
.advanced-search-container div.card-body ul li i[class*="fa-trash"] {
  color: red !important;
}
.advanced-search-container .card-header i[class*="fa"] {
  padding: 8px 4px 8px 0px !important;
}
.advanced-search-container div.card-body ul li i + i {
  margin-left: 4px !important;
  padding-left: 4px !important;
}
.advanced-search-container div.dropdown button.dropdown-toggle {
  display: block !important;
  margin-left: 8px !important;
}
.advanced-search-container div.dropdown button.dropdown-toggle,
.advanced-search-container button.btn-info2,
.advanced-search-container div.dropdown div.dropdown-menu {
  width: 100% !important;
}
.advanced-search-container div.dropdown button.dropdown-toggle {
  margin-left: 0px !important;
}
.advanced-search-container div.dropdown-menu.show {
  overflow-y: scroll;
  max-height: 50vh !important;
}
@media screen and (min-width: 320px) {
  .advanced-search-container div.dropdown button.dropdown-toggle {
    margin-left: 8px !important;
  }
}
.advanced-search-container .refresh-shared-search,
.advanced-search-container span.search-title {
  cursor: pointer !important;
}
.navbar .navbar-expand-sm .directory-navbar {
  z-index: 10 !important;
}
.advanced-search-container #myModal {
  z-index: 1111;
}
.advanced-search-container .search.active {
  font-weight: bold;
}

/* .ShareModal .DragHandle {
  cursor: grab;
}

.ShareModal .DragHandle:active {
  cursor: grabbing;
} */

div.inline-toolbar-item h4.page-title-search {
  display: inline-block;
}
div.inline-toolbar-item i {
  margin-left: 8px;
  cursor: pointer;
  font-size: 20px;
}
div.inline-item {
  display: inline;
}
td.header {
  font-size: 16px;
  background-color: #686e82;
  color: white;
}
td.header {
  padding-left: 4px;
}
td.column-name {
  padding-left: 8px;
}
table.column-categories {
  margin-bottom: 0px;
}
table.column-categories tr.hidden,
div.available-fields-container div.available-field.hidden {
  display: none;
}
div.category-header {
  font-size: 16px;
  background-color: #686e82;
  color: white;
  border-radius: 0px;
  padding-left: 4px;
}
.available-fields-container div.card {
  margin-bottom: 0px;
}
.available-fields-container div.card button.btn-link:hover {
  text-decoration: none;
}
.available-fields-container div.card button.btn-link {
  color: white;
  text-decoration: none;
}
div.result-customizer-dialog div.modal-body {
  max-height: 100vh;
  overflow-y: auto;
}
div.result-customizer-dialog div.card-body {
  overflow-y: auto;
}
.available-fields-container div.card-body {
  padding-top: 8px;
  padding-bottom: 8px;
  padding: 0px;
}
.available-fields-container div.card-body div.available-field:not(:last-child) {
  border-bottom: solid 1px #686e82;
}
.available-fields-container div.card-body div.available-field {
  padding: 4px 8px 4px 20px;
}
.available-fields-container div.card-header {
  padding-top: 4px;
  padding-bottom: 4px;
}
.available-fields-container {
  border-left: solid 1px #686e82;
  border-right: solid 1px #686e82;
  border-radius: 4px;
}
.available-fields-container.accordion div.card {
  border-radius: 0px !important;
}
.sortable-items li,
li.sortable-helper {
  background-color: rgba(0, 0, 0, 0.03);
  min-height: 30px;
  border-bottom: white;
  padding: 8px;
  margin-bottom: 4px;
}

li.sortable-helper {
  z-index: 9999;
  list-style: none;
  cursor: move !important;
}
.sortable-item-list {
  cursor: move !important;
}
.sortable-items,
.available-fields {
  max-height: 80vh;
  overflow-y: auto;
}
i[data-field-id] {
  cursor: pointer;
  margin-top: 4px;
}
ul.sortable-item-list i.fa-trash-alt,
li.sortable-helper i.fa-trash-alt {
  color: red;
  margin-top: 4px;
  float: right;
  cursor: pointer !important;
  z-index: 99999;
}
button[disabled] {
  cursor: not-allowed !important;
}

div.saved-search-email-list div.modal-header {
  background-color: #686e82;
  color: white;
}
div.saved-search-email-list div.modal-header button.close {
  color: white;
}
div.saved-search-email-list div.modal-header {
  padding: 8px;
}
div.transparent {
  opacity: 65%;
}
.mainAccordion {
  /* background-color: #ced4da !important ; */
  padding: 0.75rem;
}
.childAccordion {
  border: solid 1px #e5ebf1;
}
/* Advanced filter save search controls ends here*/
.minHeightComms {
  min-height: 18rem;
}
