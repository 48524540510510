#sidebar-menu > ul > li > button {
    color: #e6e7e8; 
    display: block; 
    padding: 13px 20px; 
    font-size: 13.3px; 
    position: relative; 
    -webkit-transition: all 0.5s; 
    transition: all 0.5s; 
}
#sidebar-menu > ul > li > a {
    color: #e6e7e8;
}
#sidebar-menu > ul > li > a.active {
    color: #ffffff !important;
    background-color: #cb2630;
}
a.logo {
    color: #ffffff;
}
