$cardColor: hsl(217, 57%, 95%);
$cardColorRead: #ffffff;
$optionsColor: #999;
$time: #9ea3ab;
$cardHover: #ebedf0;

:global(.card-noti-link) {
  text-decoration: none;
  color: $optionsColor;
}

:global(.card-noti) {
  display: flex;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  justify-content: space-between;
  background-color: $cardColor;
  font-size: 12px;
  cursor: pointer !important;
  padding: 6px;

  &:hover {
    background-color: $cardHover;
  }

  :global(.content) {
    display: flex;
    color: black;
    align-items: center;

    :global(.message) {
      margin: 0 8px;
    }

    :global(.time) {
      color: $optionsColor;
    }
  }

  :global(.image) {
    img {
      height: 40px;
      border-radius: 50%;
    }

    margin: 5px;
  }

  :global(.options) {
    visibility: hidden;
    text-align: center;
    font-size: 20px;

    :global(.option) {
      cursor: pointer;
      opacity: 0.5;
      color: $optionsColor;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    :global(.options) {
      visibility: visible;
    }
  }
}

:global(.card-noti-read) {
  display: flex;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  justify-content: space-between;
  background-color: $cardColorRead;
  font-size: 12px;
  cursor: pointer !important;
  padding: 6px;

  &:hover {
    background-color: $cardHover;
  }

  :global(.content) {
    display: flex;
    color: black;
    align-items: center;

    :global(.message) {
      margin: 0 8px;
    }

    :global(.time) {
      color: $optionsColor;
    }
  }

  :global(.image) {
    img {
      height: 40px;
      border-radius: 50%;
    }

    margin: 5px;
  }

  :global(.options) {
    visibility: hidden;
    text-align: center;
    font-size: 20px;

    :global(.option) {
      cursor: pointer;
      opacity: 0.5;
      color: $optionsColor;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    :global(.options) {
      visibility: visible;
    }
  }
}
