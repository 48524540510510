$options: #365899;
$title: #1d2129;

:global(.notifications) {
  :global(.icon) {
    position: relative;
    height: 32px;
    width: 29px;
    box-sizing: border-box;

    :global(.image) {
      height: 24px;
      width: 24px;
      cursor: pointer;
    }

    :global(.count) {
      height: 17px;
      width: 17px;
      max-width: 17px;
      min-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: -2px;
      right: 0;
      font-size: 10px;
      border-radius: 50%;
      background-color: red;
      font-weight: 400;
      color: white;
    }
  }

  :global(.container) {
    border: 1px solid rgba(100, 100, 100, 0.4);
    width: 430px;
    font-size: 12px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 1;
    background-color: white;

    :global(.header) {
      display: flex;
      justify-content: space-between;
      padding: 8px;

      :global(.header-title) {
        color: $title;
        font-weight: bold;
      }

      :global(.header-option) {
        margin: 0 5px;
        color: $options;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    :global(.items) {
      height: 400px;
      overflow-y: auto;

      :global(.loaders_noti) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    :global(.empty-notifications) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
    }

    :global(.footer) {
      text-align: center;
      padding: 5px;
      color: $options;
      text-transform: capitalize;
      font-weight: bold;

      :global(.see-all) {
        cursor: pointer;
        color: $options;
      }
    }
  }
}
