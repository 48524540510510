.equipment-control::-webkit-input-placeholder {
  font-style: italic;
  font-size: smaller;
  color: #d6d6d6;
}
.equipment-control::-moz-placeholder {
  font-style: italic;
  font-size: smaller;
  color: #d6d6d6;
}

input[id*="equipment"]::-webkit-input-placeholder {
  font-style: italic;
  font-size: small;
}
input[id*="equipment"]::-moz-placeholder {
  font-style: italic;
  font-size: small;
}

.text_ctrl .MuiInputBase-root ::-webkit-input-placeholder {
  font-style: italic;
  font-size: small;
  font-weight: lighter;
}
.text_ctrl .MuiInputBase-root ::-moz-placeholder {
  font-style: italic;
  font-size: small !important;
  font-weight: lighter !important;
}

.text_ctrl label {
  font-style: italic;
  font-size: smaller !important;
}
.text_ctrl ::-webkit-input-placeholder {
  color: #d6d6d6;
  font-size: smaller !important;
}
.text_ctrl ::-moz-placeholder {
  color: #d6d6d6;
  font-size: smaller !important;
}
.fedollaramt .fa-dollar-sign {
  padding-left: 1.1rem;
}
#depreciationTbl > thead > tr > th {
  font-style: italic;
  font-weight: bold;
  background: gainsboro;
}

#addEquipmentForm .table-responsive {
  min-height: 6rem !important;
  text-align: center;
}

.dateCtrl {
  position: relative;
  bottom: 0.7rem;
}
.equipmentUser {
  display: inline-block;
  min-width: 6rem;
}
.actionIcons {
  display: inline;
}
.AttachmentsHeader > .modal-title {
  width: 100%;
}

#addEquipmentForm > .actionBtns button {
  font-style: italic;
}
.feActions {
  background-color: #c8cdd1;
}
.minHeightSidebar {
  min-height: 33rem;
}
span.input-range__label-container {
  font-size: 9px;
  color: black;
  font-family: inherit;
  letter-spacing: 1.2px;
}

/*########################## FE Header CSS ##################################### */

.equipmentHeader-navbar-brand:hover {
  color: #759fbc !important;
  cursor: pointer !important;
}

.equipmentHeader-navbar-brand:active {
  color: #759fbc !important;
}

.equipmentHeader-navbar-brand-active {
  border-bottom: 3px solid #759fbc !important;
}

.equipmentHeader-navbar-brand button {
  cursor: pointer !important;
}
@media (max-width: 1024px) {
  .equipmentHeader-navbar {
    margin-top: 50px !important;
  }
}
.equipmentHeader-navbar {
  /* border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid darkgrey;
    border-right: 1px solid darkgrey;
    border-left: 1px solid darkgrey; */
  margin-bottom: 15px;
  background-color: #ececf1 !important;
  flex-wrap: nowrap;
  /* padding-top: 0;
  padding-bottom: 0; */
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 1;
}
.equipmentHeader-navbar-brand {
  font-size: 0.85rem !important;
}

@media (min-width: 343px) and (max-width: 488px) {
  .equipmentHeader-navbar-brand {
    font-size: calc(13px + 10 * ((100vw - 769px) / 2048)) !important;
    /* display: contents !important; */
  }

  .navbar-brand .equipmentHeader-navbar-brand {
    font-size: calc(18px + 10 * ((100vw - 769px) / 2048)) !important;
  }

  .equipmentHeader-navbar-brand:hover {
    color: #759fbc !important;
    cursor: pointer !important;
  }

  .equipmentHeader-navbar-brand:active {
    color: #759fbc !important;
  }

  .equipmentHeader-navbar-brand-active {
    border-bottom: 3px solid #759fbc !important;
  }

  .equipmentHeader-navbar-brand button {
    cursor: pointer !important;
  }

  .equipmentHeader-navbar + .container-fluid {
    /* margin-top: 100px; */
    margin-top: 6%;
  }

  @media (min-width: 200px) and (max-width: 299px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 16%;
    }
  }

  @media (min-width: 300px) and (max-width: 399px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 16%;
    }
  }

  @media screen and (min-width: 400px) and (max-width: 499px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 8%;
    }
  }

  @media (min-width: 500px) and (max-width: 599px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 16%;
    }
  }

  @media (min-width: 600px) and (max-width: 699px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 11%;
    }
  }

  @media (min-width: 700px) and (max-width: 799px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 8%;
    }
  }

  @media (min-width: 800px) and (max-width: 999px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 6%;
    }
  }

  @media (min-width: 1000px) and (max-width: 1199px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 11%;
    }
  }

  @media (min-width: 1200px) and (max-width: 1299px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 9%;
    }
  }

  @media (min-width: 1300px) and (max-width: 1399px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 9%;
    }
  }

  @media (min-width: 1400px) and (max-width: 1500px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 8%;
    }
  }

  @media (min-width: 1500px) and (max-width: 1600px) {
    .equipmentHeader-navbar + .container-fluid {
      margin-top: 7%;
    }
  }
}

@media (min-width: 489px) and (max-width: 564px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.85rem !important;
    padding: 3px 8px 3px 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.95rem !important;
    padding: 1px 6px 1px 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 7px 3px 7px !important;
  }
}

@media (min-width: 377px) and (max-width: 488px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.65rem !important;
    padding: 3px 8px 3px 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.65rem !important;
    padding: 1px 6px 1px 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

@media (min-width: 343px) and (max-width: 376px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.58rem !important;
    padding: 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.58rem !important;
    padding: 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

@media (min-width: 321px) and (max-width: 342px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.55rem !important;
    padding: 4px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.55rem !important;
    padding: 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

@media (min-width: 300px) and (max-width: 320px) {
  .feHeader-tabs {
    margin-right: 0px !important;
    display: unset;
    font-size: 0.5rem !important;
    padding: 3px !important;
  }
  .feHeaderCogIcon .fa-cog {
    font-size: 0.5rem !important;
    padding: 1px !important;
  }
  .feHeaderCogIcon > .dropdown-menu-body {
    padding: 1px !important;
    font-size: smaller;
    top: 0.6rem !important;
  }
  .feHeaderCogIcon a {
    padding: 2px !important;
  }
  .equipmentHeader-navbar {
    padding-left: 4px;
    padding-right: 4px;
  }
  .cnrbtn {
    padding: 3px 6px 3px 6px !important;
  }
}

/* ###########FEHeader Items responsivness############# */
.content > .fet-container,
.fet-container {
  margin-top: 110px !important;
}

@media (max-width: 1024px) {
  .content > .fet-container,
  .fet-container {
    margin-top: 145px !important;
  }
}
@media (max-width: 527px) {
  .content > .fet-container,
  .fet-container {
    padding: 5px !important;
    margin-top: 160px !important;
  }
}
.content > .fet-container .bg-light-blue {
  background-color: rgba(186, 236, 255, 0.59);
}
.nestedmodal .modal-content {
  margin: 6rem;
}
@media (min-width: 992px) and (max-width:1600px) {
  .fet-container th {
    font-size: 12px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size:12px !important
  }
  .equipmentFooter {
    font-size: 13px !important;
  }
  [class*="Equip_Requests_tbl_"] th {   
    font-size: 13px;
  }
}
@media (min-width: 768px) and (max-width:992px) {
  .fet-container th {
    font-size: 9px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 9px !important;
  }
  .equipmentFooter {
    font-size: 11px !important;
  }
  [class*="Equip_Requests_tbl_"] th {
      font-size: 12px;
  }
}
@media (min-width: 564px) and (max-width:768px) {
   .fet-container th {
      font-size: 8px;
    }
   .depLable {
      font-weight: 500;
      color: #000;
      font-size: 8px !important;
    }
    .equipmentFooter {
        font-size: 10px !important;
    }
    [class*="Equip_Requests_tbl_"] th {
        font-size: 11px;
    }
}

@media (min-width: 449px) and (max-width: 564px) {
  .fet-container {
    font-size: 12px;
  }
  .fet-container th {
    font-size:7px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 7px !important;
  }
  .equipmentFooter {
      font-size: 9px !important;
  }
  [class*="Equip_Requests_tbl_"] th {
      font-size: 10px;
  }

  .fet-container .feheaderTitle {
    font-size: 15px;
  }

  #addEquipmentModal .feheaderTitle {
    font-size: 11px;
  }
  .feModalHeader h5,
  .feModalHeader h4,
  .feModalHeader h3 {
    font-size: 11px;
  }
  .fet-container h5,
  .fet-container h6 {
    font-size: 13px;
  }
  .AttachmentsHeader h5,
  .AttachmentsHeader h4,
  .AttachmentsHeader span {
    font-size: small;
  }
  .nestedmodal .modal-content {
    margin: 2.7rem;
  }
  .fet-container .filter-selected {
    font-size: x-small;
    margin-bottom: 4px;
  }
}

@media (min-width: 350px) and (max-width: 448px) {
  .fet-container {
    font-size: 11px;
  }
  .fet-container th {
    font-size: 9px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 9px !important;
  }
  .equipmentFooter {
      font-size: 11px !important;
  }
  [class*="Equip_Requests_tbl_"] th {
      font-size: 9px;
  }
  .fet-container .feheaderTitle {
    font-size: 14px;
  }
  #addEquipmentModal .feheaderTitle {
    font-size: 10px;
  }
  .feModalHeader h5,
  .feModalHeader h4,
  .feModalHeader h3 {
    font-size: 10px;
  }
  .fet-container h5,
  .fet-container h6 {
    font-size: 12px;
  }
  .AttachmentsHeader h5,
  .AttachmentsHeader h4,
  .AttachmentsHeader span {
    font-size: smaller;
  }
  .nestedmodal .modal-content {
    margin: 1rem;
  }
  .fet-container .filter-selected {
    font-size: xx-small;
    margin-bottom: 4px;
  }
}

@media (min-width: 280px) and (max-width: 350px) {
  .fet-container {
    font-size: 10px;
  }
  .fet-container th {
    font-size: 8px;
  }
  .depLable {
    font-weight: 500;
    color: #000;
    font-size: 8px !important;
  }
  .equipmentFooter {
    font-size: 10px !important;
  }

  [class*="Equip_Requests_tbl_"] th {
     font-size: 8px;
  }

  .fet-container .feheaderTitle {
    font-size: 13px;
  }
  #addEquipmentModal .feheaderTitle {
    font-size: 9px;
  }
  .feModalHeader h5,
  .feModalHeader h4,
  .feModalHeader h3 {
    font-size: 9px;
  }
  .fet-container h5,
  .fet-container h6 {
    font-size: 11px;
  }
  .AttachmentsHeader h5,
  .AttachmentsHeader h4,
  .AttachmentsHeader span {
    font-size: x-small;
  }
  .nestedmodal .modal-content {
    margin: 0.8rem;
  }
  .fet-container .filter-selected {
    font-size: 0.4rem;
    margin-bottom: 4px;
  }
}

@media (min-width: 280px) and (max-width: 515px) {
  .text_ctrl .md-form {
    margin-bottom: 1.5px !important;
  }
  .eqStatusLable {
    display: contents;
  }
}

.textlink a:link {
  text-decoration: none !important;
}

.textlink a:visited {
  text-decoration: none !important;
}

.textlink a:hover {
  text-decoration: underline !important;
}

.textlink a:active {
  text-decoration: underline !important;
}

.noADData {
  align-self: center !important;
  justify-content: center !important;
  padding-top: 0.9rem !important;
}
.memberEqsTable th {
  font-size: 11px;
}
.memberEqsTable td {
  font-size: 13px;
}
.paddLeftLabel {
  padding-left: 30px;
}

@media (min-width: 280px) and (max-width: 515px) {
  .totalEquipmentsLable {
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 48px;
  }
}
.equipmentRange > div > div > span:nth-child(3) > span > span {
  display: flex;
  padding: 2.5rem;
}

.AttachmentsHeader > .modal-title {
  color: inherit !important;
}

.feTagsScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 6rem;
}

@media (min-width: 280px) and (max-width: 515px) {
  .equipExpandableView td,
  .equipExpandableView th {
    font-size: 7px;
  }
}

.approvedAmtMt .md-form {
  margin-top: 0.6rem !important;
}
.ttOnModal {
  z-index: 1151 !important;
}
.grayoutText {
  color: #cbcbcb !important;
}
.commmentStyle {
  padding: 1rem 2rem 0 0 !important;
}
.multiBudgetLinesScroll {
  max-height: 30vh;
  overflow: auto;
}
.bgclr {
  background: #f8f9fa !important;
}
.categoryFN {
  max-width: 130px;
  padding-right: 0;
  margin-right: 0;
}
/* .equipCardsTblMainDiv .react-bootstrap-table {
  overflow-x: hidden !important;
}
@media (min-width: 280px) and (max-width: 515px) {
  .equipCardsTblMainDiv .react-bootstrap-table {
    overflow-x: scroll !important;
  }
} */
/* 
table td {
  border: 1px solid #555;
  border-width: 1px 1px 0 0;
}

 
table td:nth-child(4) {
  width: 69%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

table.with-ellipsis td {
  text-overflow: ellipsis;
} */

.Equipments_list_1_Tbl td :not(.depLable) {
  font-size: 0.75rem;
}
.Equipments_list_4_Tbl td :not(.depLable) {
  font-size: 0.75rem;
}
.depLable{
  font-weight: 500;
  color: #000; 
}

.equipmentFooter{
   background-color: silver;
   font-size: medium;
 }
 .filterIconSize{
  font-size: 2.2em !important;
 }
 .reviewIconSize{
  font-size: 1.3em !important;
 }
 .listFilters .MuiInputBase-input {
  font-style: italic;
  font-size: smaller;
    
} 
.listFilters label {
   font-style: italic;
   font-size: smaller;

 }