.dropdown-menu-toggle {
  cursor: pointer;
}

.dropdown-menu-body {
  border: solid 1px lightgrey !important;
  padding: 15px !important;
  margin-top: -15px !important;
}

.dropdown-menu-header {
  color: #686e82 !important;
  margin-bottom: 0px !important;
}

.logout-button {
  border-color: #525252;
  background-color: #686e82;
  border-radius: 3px !important;
  color: white !important;
}

.logout-button:hover {
  color: white !important;
  background-color: #759fbc !important;
}

.dropdown-item-custom {
  padding-left: 0px;
}

.dropdown-item-custom:hover {
  color: #759fbc !important;
  text-decoration: underline !important;
  background: white !important;
}

.nav-link-font-size {
  font-size: 18px !important;
}

.support-link {
  color: white !important;
}

.support-link:hover {
  color: white !important;
}

.topBarSearchBtn {
  position: inherit;
  top: 0px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  border: none;
  right: 0;
  cursor: pointer;
  background: #759fbc;
  height: 34px;
  padding: 0 20px;
  border-radius: 0 30px 30px 0;
}

.pacTopBarSearchBtn {
  position: inherit;
  top: 0px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  border: none;
  right: 0;
  cursor: pointer;
  background: #759fbc;
  height: 34px;
  padding: 0 20px;
  border-radius: 0 30px 30px 0;
}

.marginRight {
  margin-right: 1vh;
}

.searchTextTopBar {
  width: 40vh !important;
}

.memberNumberLabel {
  font-size: 11px !important;
  margin-bottom: 0px !important;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.live-searched {
  background: white;
  width: 86%;
  position: absolute;
  top: 33px;
  left: 25px;
  border-radius: 2px;
  box-shadow: 0px 2px 5px 0 #423d3d8a;
}

.live-searched .searched-item {
  border-bottom: 1px solid #bfbfbf;
  padding: 2px 10px 0;
  cursor: pointer;
  z-index: 290;
}

a.anker:hover {
  color: #0056b3 !important;
}

.live-searched .searched-item .value {
  flex: 0 0 77%;
  max-width: 77%;
}

.live-searched .value h4 {
  display: inline-block;
  font-size: 15px;
  color: #000000;
  margin: 0;
  vertical-align: top;
  margin-left: 2px;
  font-weight: 500;
}

.live-searched i {
  font-size: 9px;
  position: relative;
  top: -2px;
  color: #737373;
  left: -5px;
}

.live-searched .value .member {
  font-size: 12px;
  color: #4a4a4a;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
  font-style: italic;
}

.email-phone a.anker i {
  top: -0.6px;
  color: #737373;
}

.live-searched .searched-item .zdc-arrow {
  text-align: right;
  flex: 0 0 23%;
  max-width: 23%;
}

.live-searched .email-phone {
  display: inline-block;
  margin: 0;
  position: relative;
  margin-left: 0;
  top: -4px;
}

.email-phone a.anker {
  font-size: 12px;
  color: #626ed4;
  margin-left: 2px;
  z-index: 320;
}

.email-phone p {
  display: block;
  color: #353535;
  font-weight: 400;
  margin-left: 10px;
  font-size: 12px;
  margin: 0;
  position: relative;
  top: -3px;
}

.email-phone p i {
  top: -1px;
  color: #737373;
  font-size: 8px;
}

.live-searched .searched-item .zdc-arrow p {
  font-size: 12px;
  font-weight: 500;
  color: #4e4e4e;
  margin: 0;
}

.zdc-arrow span i {
  position: relative;
  right: 0;
  top: -5px;
  color: #759fbc;
  font-size: 15px;
}

nav.navbar-custom-searchbox {
  z-index: 300;
}

.content-page {
  z-index: 200;
}

.searched-item:last-child {
  border: 0px;
}

.live-searched .searched-item:hover {
  transition: background-color 0.3s ease;
  background: #eeeef3;
}

.searched-scroll {
  max-height: 303px;
  overflow: auto;
}

.search-view-all {
  margin: 0;
  padding: 2px 15px;
  width: 100%;
  text-align: right;
  border-top: 1px solid #bfbfbf;
  background: #ececf1;
}

.search-view-all p i {
  left: -3px;
  top: 0;
  color: #737373;
}

.search-view-all p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  color: #759fbc;
  font-family: sans-serif;
  cursor: pointer;
}

.search-not-found {
  width: 100%;
  text-align: center;
  padding: 25px 0 20px;
}

.search-not-found h4 {
  font-size: 14px;
  font-family: sans-serif;
  margin: 0;
  font-weight: 500;
  color: #191919;
}

@media (max-width: 1460px) {
  a.anker {
    display: block;
    width: 100% !important;
  }

  .zdc-arrow span i {
    vertical-align: middle;
    top: -4px;
  }
}

a.row-search-bar {
  display: flex;
  flex-wrap: wrap;
}

.live-searched .member-no {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 400;
  margin: 0;
  font-style: italic;
  margin-left: 5px;
}

.cell-phone {
  color: black !important;
  font-weight: 600 !important;
  font-size: 9px !important;
}

@media (max-width: 470px) {
  .live-searched .value h4 {
    margin-bottom: 3px !important;
  }

  .live-searched span.member-no {
    display: block;
    margin-left: 7px;
  }
}
