.MuiAutocomplete-paper{
    max-height: 200px !important;
    overflow-y: auto !important;
}

.MuiFormLabel-root.Mui-focused{
color:#4285f4 !important;
font-size: 1.1rem !important;
}
.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #4285f4;
    pointer-events: none;
}
.MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 24px) scale(1);
    color: #979797 !important;
    font-weight: normal !important;
}