.regisMemActs{
    margin: auto;
    background-color: white;
}
.regisMems{
    margin-top: 2px;
}
.disStyleCheck span{
background-color: #ccc !important;  
}
.clear-icon {
    position: absolute;
    right: 20px;
    top: 2px;
    cursor: pointer;
    display: none;
}
.radioBtn-clear-icon{
position: absolute;
    top: 2px;
    right: 20px;
    cursor: pointer;
}
.custom-select:focus+.clear-icon,
.custom-select:not([value=""])+.clear-icon {
    display: block;
}
.checkoutPage{
    font-size: 2vh;
}
.ct-alias .input-group-text{
    font-size: small;
    font-weight: bold;
}
.paynow{
    height: 18rem;
}
.discountInput{
    padding: 0 3vh 0  3vh;
}
.grpInput{
    height: auto;
}